// Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Tab, Tabs, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import useAuth from '../utils/useAuth';

const styles = () => ({

    root: {
        width: '100%',
    },
    selected: {
        fontWeight: 600,
        transition: 'font-weight ease-in-out 0.2s'
    },
    tabIndicator: {
        display: 'none'
    }
});


const TabRouter = (props) => {
    const auth = useAuth();
    const mediaQuery = useMediaQuery('(min-width: 1080px)');
    let location = useLocation();

    /**
     * Checks for user permissions for both the management routes and the tag content routes
     * @param {QuickLink} value Route to verify
     * @returns True or False depending if the user is authorized to use/see the route
     */
    const isAuthorized = (value) => {
        if (value.access) {
            return value.access.some((role) => auth.user.role.includes(role)) ? true : false;
        }
        if (auth.user.role.includes('super_admin') || auth.user.role.includes('tenant_owner') || auth.user.role.includes('tenant_admin')) return true;
        if (value.to.startsWith('/content') && (!auth.user.role.includes('super_admin') && !auth.user.role.includes('tenant_owner') && !auth.user.role.includes('tenant_admin'))) return true;
        return false;
    }

    return (
        <Tabs 
            className={props.classes.root} 
            indicatorColor="secondary"
            value={location.pathname || '/'}
            variant={mediaQuery ? "standard" : "scrollable"}
            scrollButtons={mediaQuery ? false : "auto"}
            centered={mediaQuery}
            classes={{ indicator: props.classes.tabIndicator }}
        >
            {props.tabs.map((item, index) => (
                !item.hidden && isAuthorized(item) && (
                    <Tab key={index} label={item.label} value={item.value} classes={{ selected: props.classes.selected }} disabled={item.disabled} component={Link} to={item.value} />
                )
            ))}
        </Tabs>
    )
}

TabRouter.propTypes = {
    classes: PropTypes.object,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        disabled: PropTypes.bool,
        hidden: PropTypes.bool,
        access: PropTypes.arrayOf(PropTypes.string.isRequired)
    }).isRequired).isRequired
}

export default withStyles(TabRouter, styles);
