import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_SCHEMA, DELETE_DEVICE_CONFIGURATION, DELETE_GROUP_CONFIGURATION, DELETE_SCHEMA, DELETE_TENANT_CONFIGURATION, FETCH_CONFIGURATION, FETCH_DEVICE_CONFIGURATION, FETCH_GROUP_CONFIGURATION, FETCH_SCHEMAS, FETCH_TENANT_SCHEMAS, UPDATE_DEVICE_CONFIGURATION, UPDATE_GROUP_CONFIGURATION, UPDATE_SCHEMA, UPDATE_TENANT_CONFIGURATION, UPDATE_TENANT_SCHEMAS } from '../constants/configurationsTypes';
import { fetchSchemas as fetchSchemasAction, fetchTenantSchemas as fetchTenantSchemasAction, fetchDeviceConfiguration as fetchDeviceConfigurationAction, fetchGroupConfiguration as fetchGroupConfigurationAction, fetchConfigurationFailure, fetchConfigurationFinal, fetchConfigurationSuccess, fetchSchemasFailure, fetchSchemasFinal, fetchSchemasSuccess, notifyOpen, fetchConfiguration as fetchConfigurationAction, fetchGroupConfigurationSuccess, fetchGroupConfigurationFailure, fetchGroupConfigurationFinal, fetchDeviceConfigurationSuccess, fetchDeviceConfigurationFailure, fetchDeviceConfigurationFinal, fetchTenantSchemasSuccess, fetchTenantSchemasFailure, fetchTenantSchemasFinal, createSchemaSuccess } from '../actions';

function fetchSchemasRequest() {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/schemas`);
}

function* fetchSchemas() {
    try {
        const { data } = yield call(fetchSchemasRequest);
        
        yield put(fetchSchemasSuccess({
            data
        }));
    } catch (error) {
        yield put(fetchSchemasFailure());
    } finally {
        yield put(fetchSchemasFinal());
    }
}

export function* watchFetchSchemas() {
    yield takeLatest(FETCH_SCHEMAS, fetchSchemas);
}

function createSchemaRequest(schema) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/schemas`, schema);
}

function* createSchema({ payload }) {
    try {
        const { data } = yield call(createSchemaRequest, payload.schema);

        yield put(createSchemaSuccess({
            data
        }));

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created schema'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create schema'
        }));
    } finally {
        yield put(fetchSchemasAction());
    }
}

export function* watchCreateSchema() {
    yield takeLatest(CREATE_SCHEMA, createSchema);
}

function updateSchemaRequest(schemaId, schema) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/schemas/${schemaId}`, schema);
}

function* updateSchema({ payload }) {
    try {
        yield call(updateSchemaRequest, payload.schemaId, payload.schema);

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully updated schema'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to update schema'
        }));
    } finally {
        yield put(fetchSchemasAction());
        yield put(fetchTenantSchemasAction(payload.tenantId));
    }
}

export function* watchUpdateSchema() {
    yield takeLatest(UPDATE_SCHEMA, updateSchema);
}

function deleteSchemaRequest(schemaId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/schemas/${schemaId}`);
}

function* deleteSchema({ payload }) {
    try {
        yield call(deleteSchemaRequest, payload.schemaId);

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully deleted schema'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to delete schema'
        }));
    } finally {
        yield put(fetchSchemasAction());
        yield put(fetchTenantSchemasAction(payload.tenantId));
    }
}

export function* watchDeleteSchema() {
    yield takeLatest(DELETE_SCHEMA, deleteSchema);
}


function fetchConfigurationRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/configurations`);
}

function fetchTenantSchemasRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/schemas`);
}

function* fetchTenantSchemas({ payload }) {
    try {
        const { data } = yield call(fetchTenantSchemasRequest, payload.tenantId);

        yield put(fetchTenantSchemasSuccess({
            data
        }));
    } catch (error) {
        yield put(fetchTenantSchemasFailure());
    } finally {
        yield put(fetchTenantSchemasFinal());
    }
}

export function* watchFetchTenantSchemas() {
    yield takeLatest(FETCH_TENANT_SCHEMAS, fetchTenantSchemas);
}

function updateTenantSchemasRequest(tenantId, enabledSchemas, keepConfigurations) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/schemas`, enabledSchemas, {
        params: {
            keepConfigurations
        }
    });
}

function* updateTenantSchemas({ payload }) {
    try {
        yield call(updateTenantSchemasRequest, payload.tenantId, payload.enabledSchemas, payload.keepConfigurations);

        yield put(notifyOpen({
            type: 'success',
            text: 'Modules successfully saved'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Modules failed to save'
        }));
    } finally {
        yield put(fetchTenantSchemasAction(payload.tenantId));
    }
}

export function* watchUpdateTenantSchemas() {
    yield takeLatest(UPDATE_TENANT_SCHEMAS, updateTenantSchemas);
}

function* fetchConfiguration({ payload }) {
    try {
        const { data } = yield call(fetchConfigurationRequest, payload.tenantId);

        yield put(fetchConfigurationSuccess({
            data
        }));
    } catch (error) {
        yield put(fetchConfigurationFailure());
    } finally {
        yield put(fetchConfigurationFinal());
    }
}

export function* watchFetchConfiguration() {
    yield takeLatest(FETCH_CONFIGURATION, fetchConfiguration);
}

function fetchGroupConfigurationRequest(groupId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/groups/${groupId}/configurations`);
}

function* fetchGroupConfiguration({ payload }) {
    try {
        /**
         * If the group configuration returns null or an empty array it should
         * * fetch the tenant configuration instead, and
         * * set the fetched group flag correctly
         */
        let response = yield call(fetchGroupConfigurationRequest, payload.groupId);
        let fetchedGroup = true;

        if (!response.data || response.data.length < 1) {
            response = yield call(fetchConfigurationRequest, payload.tenantId);
            fetchedGroup = false;
        }

        yield put(fetchGroupConfigurationSuccess({
            data: response.data,
            fetchedGroup
        }));
    } catch (error) {
        yield put(fetchGroupConfigurationFailure());
    } finally {
        yield put(fetchGroupConfigurationFinal());
    }
}

export function* watchFetchGroupConfiguration() {
    yield takeLatest(FETCH_GROUP_CONFIGURATION, fetchGroupConfiguration);
}

function updateTenantConfigurationRequest(tenantId, configuration) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/configurations`, configuration);
}

function* updateTenantConfiguration({ payload }) {
    try {
        yield call(updateTenantConfigurationRequest, payload.tenantId, payload.configuration);

        yield put(notifyOpen({
            type: 'success',
            text: 'Configuration successfully saved'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Configuration failed to save'
        }));
    } finally {
        yield put(fetchConfigurationAction(payload.tenantId));
    }
}

export function* watchUpdateTenantConfiguration() {
    yield takeLatest(UPDATE_TENANT_CONFIGURATION, updateTenantConfiguration);
}

function updateGroupConfigurationRequest(groupId, configuration) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/groups/${groupId}/configurations`, configuration);
}

function* updateGroupConfiguration({ payload }) {
    try {
        yield call(updateGroupConfigurationRequest, payload.groupId, payload.configuration);

        yield put(notifyOpen({
            type: 'success',
            text: 'Configuration successfully saved'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Configuration failed to save'
        }));
    } finally {
        yield put(fetchGroupConfigurationAction(payload.groupId));
    }
}

export function* watchUpdateGroupConfiguration() {
    yield takeLatest(UPDATE_GROUP_CONFIGURATION, updateGroupConfiguration);
}


function deleteTenantConfigurationRequest(tenantId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/configurations`);
}

function* deleteTenantConfiguration({ payload }) {
    try {
        yield call(deleteTenantConfigurationRequest, payload.tenantId);

        yield put(notifyOpen({
            type: 'success',
            text: 'Configuration successfully reset'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Configuration failed to reset'
        }));
    } finally {
        yield put(fetchConfigurationAction(payload.tenantId));
    }
}

export function* watchDeleteTenantConfiguration() {
    yield takeLatest(DELETE_TENANT_CONFIGURATION, deleteTenantConfiguration);
}


function deleteGroupConfigurationRequest(groupId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/groups/${groupId}/configurations`);
}

function* deleteGroupConfiguration({ payload }) {
    try {
        yield call(deleteGroupConfigurationRequest, payload.groupId);

        yield put(notifyOpen({
            type: 'success',
            text: 'Group configuration successfully reset'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Group configuration failed to reset'
        }));
    } finally {
        yield put(fetchGroupConfigurationAction(payload.groupId, payload.tenantId));
    }
}

export function* watchDeleteGroupConfiguration() {
    yield takeLatest(DELETE_GROUP_CONFIGURATION, deleteGroupConfiguration);
}

function fetchDeviceConfigurationRequest(deviceId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}/configurations`);
}

function* fetchDeviceConfiguration({ payload }) {
    try {
        /**
         * If the device configuration returns null or an empty array it should
         * * fetch the tenant configuration instead, and
         * * set the fetched device flag correctly
         */
        let response = yield call(fetchDeviceConfigurationRequest, payload.deviceId);
        let fetchedDevice = true;

        if ((!response.data || response.data.length < 1) && payload.groupId) {
            response = yield call(fetchGroupConfigurationRequest, payload.groupId);
            fetchedDevice = false;
        }

        if (!response.data || response.data.length < 1) {
            response = yield call(fetchConfigurationRequest, payload.tenantId);
            fetchedDevice = false;
        }

        yield put(fetchDeviceConfigurationSuccess({
            data: response.data,
            fetchedDevice
        }));
    } catch (error) {
        yield put(fetchDeviceConfigurationFailure());
    } finally {
        yield put(fetchDeviceConfigurationFinal());
    }
}

export function* watchFetchDeviceConfiguration() {
    yield takeLatest(FETCH_DEVICE_CONFIGURATION, fetchDeviceConfiguration);
}

function updateDeviceConfigurationRequest(deviceId, configuration) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}/configurations`, configuration);
}

function* updateDeviceConfiguration({ payload }) {
    try {
        yield call(updateDeviceConfigurationRequest, payload.deviceId, payload.configuration);

        yield put(notifyOpen({
            type: 'success',
            text: 'Configuration successfully saved'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Configuration failed to save'
        }));
    } finally {
        yield put(fetchDeviceConfigurationAction(payload.deviceId));
    }
}

export function* watchUpdateDeviceConfiguration() {
    yield takeLatest(UPDATE_DEVICE_CONFIGURATION, updateDeviceConfiguration);
}

function deleteDeviceConfigurationRequest(deviceId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}/configurations`);
}

function* deleteDeviceConfiguration({ payload }) {
    try {
        yield call(deleteDeviceConfigurationRequest, payload.deviceId);

        yield put(notifyOpen({
            type: 'success',
            text: 'Device configuration successfully reset'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Device configuration failed to reset'
        }));
    } finally {
        yield put(fetchDeviceConfigurationAction(payload.deviceId, payload.tenantId, payload.groupId));
    }
}

export function* watchDeleteDeviceConfiguration() {
    yield takeLatest(DELETE_DEVICE_CONFIGURATION, deleteDeviceConfiguration);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchSchemas),
        fork(watchFetchConfiguration),
        fork(watchFetchGroupConfiguration),
        fork(watchUpdateTenantConfiguration),
        fork(watchUpdateGroupConfiguration),
        fork(watchDeleteTenantConfiguration),
        fork(watchDeleteGroupConfiguration),
        fork(watchFetchDeviceConfiguration),
        fork(watchUpdateDeviceConfiguration),
        fork(watchDeleteDeviceConfiguration),
        fork(watchFetchTenantSchemas),
        fork(watchUpdateTenantSchemas),
        fork(watchCreateSchema),
        fork(watchUpdateSchema),
        fork(watchDeleteSchema),
    ])
}