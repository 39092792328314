export const FETCH_TAG_CATEGORIES = 'fetch_tag_categories';
export const FETCH_TAG_CATEGORIES_SUCCESS = 'fetch_tag_categories_success';
export const FETCH_TAG_CATEGORIES_FAILURE = 'fetch_tag_categories_failure';
export const FETCH_TAG_CATEGORIES_FINAL = 'fetch_tag_categories_final';

export const FETCH_TAG_CATEGORY = 'fetch_tag_category';
export const FETCH_TAG_CATEGORY_SUCCESS = 'fetch_tag_category_success';
export const FETCH_TAG_CATEGORY_FAILURE = 'fetch_tag_category_failure';
export const FETCH_TAG_CATEGORY_FINAL = 'fetch_tag_category_final';

export const CREATE_TAG_CATEGORY = 'create_tag_category';
export const CREATE_TAG_CATEGORY_SUCCESS = 'create_tag_category_success';
export const CREATE_TAG_CATEGORY_FAILURE = 'create_tag_category_failure';

export const UPDATE_TAG_CATEGORY = 'update_tag_category';
export const UPDATE_TAG_CATEGORY_SUCCESS = 'update_tag_category_success';
export const UPDATE_TAG_CATEGORY_FAILURE = 'update_tag_category_failure';

export const DELETE_TAG_CATEGORY = 'delete_tag_category';
export const DELETE_TAG_CATEGORY_SUCCESS = 'delete_tag_category_success';
export const DELETE_TAG_CATEGORY_FAILURE = 'delete_tag_category_failure';
