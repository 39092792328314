import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { ADD_TAG_CONTENT, DELETE_TAG_CONTENT, FETCH_TAG_CONTENTS, FETCH_TAG_CONTENT_ITEM, UPDATE_TAG_CONTENT } from '../constants/tagContentTypes';
import { fetchTagContents as fetchTagContentsAction, fetchTagContentItemFailure, fetchTagContentItemFinal, fetchTagContentItemSuccess, fetchTagContentsFailure, fetchTagContentsFinal, fetchTagContentsSuccess, notifyOpen } from '../actions';

function fetchTagContentsRequest(tagId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/anchors/${tagId}/contents`);
}

function* fetchTagContents({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagContentsRequest, payload.tagId);

        yield put(fetchTagContentsSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagContentsFailure());
    } finally {
        yield put(fetchTagContentsFinal());
    }
}

export function* watchFetchTagContents() {
    yield takeLatest(FETCH_TAG_CONTENTS, fetchTagContents);
}

function fetchTagContentItemRequest(tagContentId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/contents/${tagContentId}`);
}

function* fetchTagContentItem({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagContentItemRequest, payload.tagContentId);

        yield put(fetchTagContentItemSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagContentItemFailure());
    } finally {
        yield put(fetchTagContentItemFinal());
    }
}

export function* watchFetchTagContentItem() {
    yield takeLatest(FETCH_TAG_CONTENT_ITEM, fetchTagContentItem);
}

function addTagContentRequest(tagId, tagContent) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/anchors/${tagId}/contents`, tagContent);
}

function* addTagContent({ payload }) {
    try {
        yield call(addTagContentRequest, payload.tagId, payload.tagContent);
        yield put(notifyOpen({
            text: 'Successfully added item',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to add item',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagContentsAction(payload.tagId));
    }
}

export function* watchAddTagContent() {
    yield takeLatest(ADD_TAG_CONTENT, addTagContent);
}

function updateTagContentRequest(tagContentItemId, tagContent) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/contents/${tagContentItemId}`, tagContent);
}

function* updateTagContent({ payload }) {
    try {
        yield call(updateTagContentRequest, payload.tagContentItemId, payload.tagContent);
        yield put(notifyOpen({
            text: 'Successfully updated item',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to update item',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagContentsAction(payload.tagId));
    }
}

export function* watchUpdateTagContent() {
    yield takeLatest(UPDATE_TAG_CONTENT, updateTagContent);
}

function deleteTagContentRequest(tagContentId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/contents/${tagContentId}`);
}

function* deleteTagContent({ payload }) {
    try {
        yield call(deleteTagContentRequest, payload.tagContentId);
        yield put(notifyOpen({
            text: 'Successfully deleted item',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to delete item',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagContentsAction(payload.tagId));
    }
}

export function* watchDeleteTagContent() {
    yield takeLatest(DELETE_TAG_CONTENT, deleteTagContent);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchTagContents),
        fork(watchFetchTagContentItem),
        fork(watchAddTagContent),
        fork(watchUpdateTagContent),
        fork(watchDeleteTagContent)
    ]);
}