import { FETCH_TAG_CATEGORY, FETCH_TAG_CATEGORIES, FETCH_TAG_CATEGORIES_FAILURE, FETCH_TAG_CATEGORIES_FINAL, FETCH_TAG_CATEGORIES_SUCCESS, FETCH_TAG_CATEGORY_FAILURE, FETCH_TAG_CATEGORY_FINAL, FETCH_TAG_CATEGORY_SUCCESS, DELETE_TAG_CATEGORY, DELETE_TAG_CATEGORY_FAILURE, DELETE_TAG_CATEGORY_SUCCESS, UPDATE_TAG_CATEGORY, UPDATE_TAG_CATEGORY_FAILURE, UPDATE_TAG_CATEGORY_SUCCESS, CREATE_TAG_CATEGORY, CREATE_TAG_CATEGORY_SUCCESS, CREATE_TAG_CATEGORY_FAILURE } from '../constants/tagCategoryTypes';

export const fetchTagCategories = (tenantId) => {
    return {
        type: FETCH_TAG_CATEGORIES,
        payload: {
            tenantId
        }
    }
}

export const fetchTagCategoriesSuccess = (tagCategories) => {
    return {
        type: FETCH_TAG_CATEGORIES_SUCCESS,
        payload: tagCategories
    }
}

export const fetchTagCategoriesFailure = () => {
    return {
        type: FETCH_TAG_CATEGORIES_FAILURE
    }
}

export const fetchTagCategoriesFinal = () => {
    return {
        type: FETCH_TAG_CATEGORIES_FINAL
    }
}

export const fetchTagCategory = (tagCategoryId) => {
    return {
        type: FETCH_TAG_CATEGORY,
        payload: {
            tagCategoryId
        }
    }
}

export const fetchTagCategorySuccess = (tagCategory) => {
    return {
        type: FETCH_TAG_CATEGORY_SUCCESS,
        payload: tagCategory
    }
}

export const fetchTagCategoryFailure = () => {
    return {
        type: FETCH_TAG_CATEGORY_FAILURE
    }
}

export const fetchTagCategoryFinal = () => {
    return {
        type: FETCH_TAG_CATEGORY_FINAL
    }
}

export const createTagCategory = (tenantId, tagCategory) => {
    return {
        type: CREATE_TAG_CATEGORY,
        payload: {
            tenantId,
            tagCategory
        }
    }
}

export const createTagCategorySuccess = () => {
    return {
        type: CREATE_TAG_CATEGORY_SUCCESS
    }
}

export const createTagCategoryFailure = () => {
    return {
        type: CREATE_TAG_CATEGORY_FAILURE
    }
}

export const updateTagCategory = (tenantId, tagCategory) => {
    return {
        type: UPDATE_TAG_CATEGORY,
        payload: {
            tenantId,
            tagCategory
        }
    }
}

export const updateTagCategorySuccess = () => {
    return {
        type: UPDATE_TAG_CATEGORY_SUCCESS
    }
}

export const updateTagCategoryFailure = () => {
    return {
        type: UPDATE_TAG_CATEGORY_FAILURE
    }
}

export const deleteTagCategory = (tenantId, tagCategoryId) => {
    return {
        type: DELETE_TAG_CATEGORY,
        payload: {
            tenantId,
            tagCategoryId
        }
    }
}

export const deleteTagCategorySuccess = () => {
    return {
        type: DELETE_TAG_CATEGORY_SUCCESS
    }
}

export const deleteTagCategoryFailure = () => {
    return {
        type: DELETE_TAG_CATEGORY_FAILURE
    }
}