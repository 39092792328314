import React from 'react';

/**
 * React hook that provides dynamic `setInterval` functionality
 * @param { function } callback The `callback` function to be repeated on interval
 * @param { number } delay The `delay` in milliseconds between the repeat of `callback` calls
 */
export default (callback, delay) => {
    const savedCallback = React.useRef();

    /**
     * Remember latest callback
     */
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    /**
     * Setting up interval
     */
    React.useEffect(() => {
        const tick = () => {
            savedCallback.current();
        }
        if (delay) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}