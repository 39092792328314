import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { FETCH_TAG_CATEGORIES, FETCH_TAG_CATEGORY, CREATE_TAG_CATEGORY, UPDATE_TAG_CATEGORY, DELETE_TAG_CATEGORY } from '../constants/tagCategoryTypes';
import { notifyOpen, fetchTagCategories as fetchTagCategoriesAction, fetchTagCategoriesSuccess, fetchTagCategoriesFailure, fetchTagCategoriesFinal, fetchTagCategorySuccess, fetchTagCategoryFailure, fetchTagCategoryFinal } from '../actions';

function fetchTagCategoriesRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Categories`);
}

function* fetchTagCategories({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagCategoriesRequest, payload.tenantId);

        yield put(fetchTagCategoriesSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagCategoriesFailure());
    } finally {
        yield put(fetchTagCategoriesFinal());
    }
}

export function* watchFetchTagCategories() {
    yield takeLatest(FETCH_TAG_CATEGORIES, fetchTagCategories);
}

function fetchTagCategoryRequest(tagCategoryId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Categories/${tagCategoryId}`);
}

function* fetchTagCategory({ payload }) {
    try {
        const { data } = yield call(fetchTagCategoryRequest, payload.tagCategoryId);

        yield put(fetchTagCategorySuccess(data));
    } catch (error) {
        yield put(fetchTagCategoryFailure());
    } finally {
        yield put(fetchTagCategoryFinal());
    }
}

export function* watchFetchTagCategory() {
    yield takeLatest(FETCH_TAG_CATEGORY, fetchTagCategory);
}

function createTagCategoryRequest(tenantId, tagCategory) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Categories`, tagCategory);
}

function* createTagCategory({ payload }) {
    try {
        yield call(createTagCategoryRequest, payload.tenantId, payload.tagCategory);
        yield put(notifyOpen({
            text: 'Successfully added category',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to add category',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagCategoriesAction(payload.tenantId));
    }
}

export function* watchCreateTagCategory() {
    yield takeLatest(CREATE_TAG_CATEGORY, createTagCategory);
}

function updateTagCategoryRequest(tagCategory) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/Categories/${tagCategory.id}`, tagCategory);
}

function* updateTagCategory({ payload }) {
    try {
        yield call(updateTagCategoryRequest, payload.tagCategory);
        yield put(notifyOpen({
            text: 'Sucessfully updated category',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to update category',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagCategoriesAction(payload.tenantId));
    }
}

export function* watchUpdateTagCategory() {
    yield takeLatest(UPDATE_TAG_CATEGORY, updateTagCategory);
}

function deleteTagCategoryRequest(tagCategoryId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/Categories/${tagCategoryId}`);
}

function* deleteTagCategory({ payload }) {
    try {
        yield call(deleteTagCategoryRequest, payload.tagCategoryId);
        yield put(notifyOpen({
            text: 'Successfully deleted category',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to delete category',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagCategoriesAction(payload.tenantId));
    }
}

export function* watchDeleteTagCategory() {
    yield takeLatest(DELETE_TAG_CATEGORY, deleteTagCategory);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchTagCategories),
        fork(watchFetchTagCategory),
        fork(watchCreateTagCategory),
        fork(watchUpdateTagCategory),
        fork(watchDeleteTagCategory)
    ]);
}
