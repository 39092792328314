import { CREATE_GROUP_DEVICE, CREATE_GROUP_DEVICE_FAILURE, CREATE_GROUP_DEVICE_SUCCESS, CREATE_TENANT_DEVICE, CREATE_TENANT_DEVICE_FAILURE, CREATE_TENANT_DEVICE_SUCCESS, DELETE_DEVICE, DELETE_DEVICE_FAILURE, DELETE_DEVICE_SUCCESS, FETCH_DEVICE_HEARTBEAT, FETCH_DEVICE_HEARTBEAT_FAILURE, FETCH_DEVICE_HEARTBEAT_FINAL, FETCH_DEVICE_HEARTBEAT_SUCCESS, FETCH_DEVICE_STATUS, FETCH_DEVICE_STATUS_FAILURE, FETCH_DEVICE_STATUS_FINAL, FETCH_DEVICE_STATUS_SUCCESS, FETCH_GROUP_DEVICES, FETCH_GROUP_DEVICES_FAILURE, FETCH_GROUP_DEVICES_FINAL, FETCH_GROUP_DEVICES_SUCCESS, FETCH_TENANT_DEVICES, FETCH_TENANT_DEVICES_FAILURE, FETCH_TENANT_DEVICES_FINAL, FETCH_TENANT_DEVICES_SUCCESS, REGISTER_DEVICE, REGISTER_DEVICE_FAILURE, REGISTER_DEVICE_SUCCESS, RESEND_ACTIVATION, RESEND_ACTIVATION_FAILURE, RESEND_ACTIVATION_SUCCESS, UPDATE_DEVICE, UPDATE_DEVICE_FAILURE, UPDATE_DEVICE_SUCCESS, VALIDATE_DEVICE, VALIDATE_DEVICE_FAILURE, VALIDATE_DEVICE_SUCCESS } from '../constants/devicesTypes';


export const fetchTenantDevices = (tenantId) => {
    return {
        type: FETCH_TENANT_DEVICES,
        payload: {
            tenantId
        }
    }
}

export const fetchTenantDevicesSuccess = (devices) => {
    return {
        type: FETCH_TENANT_DEVICES_SUCCESS,
        payload: devices
    }
}

export const fetchTenantDevicesFailure = () => {
    return {
        type: FETCH_TENANT_DEVICES_FAILURE
    }
}

export const fetchTenantDevicesFinal = () => {
    return {
        type: FETCH_TENANT_DEVICES_FINAL
    }
}

export const createTenantDevice = (tenantId, type, ownerEmail) => {
    return {
        type: CREATE_TENANT_DEVICE,
        payload: {
            tenantId, type, ownerEmail
        }
    }
}

export const createTenantDeviceSuccess = () => {
    return {
        type: CREATE_TENANT_DEVICE_SUCCESS
    }
}

export const createTenantDeviceFailure = () => {
    return {
        type: CREATE_TENANT_DEVICE_FAILURE
    }
}

export const fetchGroupDevices = (groupId) => {
    return {
        type: FETCH_GROUP_DEVICES,
        payload: {
            groupId
        }
    }
}

export const fetchGroupDevicesSuccess = (devices) => {
    return {
        type: FETCH_GROUP_DEVICES_SUCCESS, 
        payload: devices
    }
} 

export const fetchGroupDevicesFailure = () => {
    return {
        type: FETCH_GROUP_DEVICES_FAILURE
    }
}

export const fetchGroupDevicesFinal = () => {
    return {
        type: FETCH_GROUP_DEVICES_FINAL
    }
}

export const createGroupDevice = (groupId, type, ownerEmail) => {
    return {
        type: CREATE_GROUP_DEVICE,
        payload: {
            groupId, type, ownerEmail
        }
    }
}

export const createGroupDeviceSuccess = () => {
    return {
        type: CREATE_GROUP_DEVICE_SUCCESS
    }
}

export const createGroupDeviceFailure = () => {
    return {
        type: CREATE_GROUP_DEVICE_FAILURE
    }
}

export const updateDevice = (fetchType, deviceId, ownerEmail, deviceType, groupId, tenantId) => {
    return {
        type: UPDATE_DEVICE,
        payload: {
            deviceId, ownerEmail, fetchType, tenantId, groupId, deviceType
        }
    }
}

export const updateDeviceSuccess = () => {
    return {
        type: UPDATE_DEVICE_SUCCESS
    }
}

export const updateDeviceFailure = () => {
    return {
        type: UPDATE_DEVICE_FAILURE
    }
}

export const deleteDevice = (fetchType, deviceId, tenantId, groupId) => {
    return {
        type: DELETE_DEVICE,
        payload: {
            deviceId, tenantId, groupId, fetchType
        }
    }
}

export const deleteDeviceSuccess = () => {
    return {
        type: DELETE_DEVICE_SUCCESS,
    }
}

export const deleteDeviceFailure = () => {
    return {
        type: DELETE_DEVICE_FAILURE,
    }
}

export const registerDevice = (identifier, registerCode, fetchType, tenantId, groupId) => {
    return {
        type: REGISTER_DEVICE,
        payload: {
            identifier, registerCode, fetchType, tenantId, groupId
        }
    }
}

export const registerDeviceSuccess = () => {
    return {
        type: REGISTER_DEVICE_SUCCESS
    }
}

export const registerDeviceFailure = () => {
    return {
        type: REGISTER_DEVICE_FAILURE
    }
}

export const validateDevice = (identifier, fetchType, tenantId, groupId) => {
    return {
        type: VALIDATE_DEVICE,
        payload: {
            identifier, fetchType, tenantId, groupId
        }
    }
}

export const validateDeviceSuccess = () => {
    return {
        type: VALIDATE_DEVICE_SUCCESS
    }
}

export const validateDeviceFailure = () => {
    return {
        type: VALIDATE_DEVICE_FAILURE
    }
}


export const fetchDeviceStatus = (tenantId) => {
    return {
        type: FETCH_DEVICE_STATUS,
        payload: {
            tenantId
        }
    }
}

export const fetchDeviceStatusSuccess = (statuses) => {
    return {
        type: FETCH_DEVICE_STATUS_SUCCESS,
        payload: statuses
    }
}

export const fetchDeviceStatusFailure = () => {
    return {
        type: FETCH_DEVICE_STATUS_FAILURE
    }
}

export const fetchDeviceStatusFinal = () => {
    return {
        type: FETCH_DEVICE_STATUS_FINAL
    }
}


export const fetchDeviceHeartbeat = (tenantId) => {
    return {
        type: FETCH_DEVICE_HEARTBEAT,
        payload: {
            tenantId
        }
    }
}

export const fetchDeviceHeartbeatSuccess = (heartbeats) => {
    return {
        type: FETCH_DEVICE_HEARTBEAT_SUCCESS,
        payload: heartbeats
    }
}

export const fetchDeviceHeartbeatFailure = () => {
    return {
        type: FETCH_DEVICE_HEARTBEAT_FAILURE
    }
}

export const fetchDeviceHeartbeatFinal = () => {
    return {
        type: FETCH_DEVICE_HEARTBEAT_FINAL
    }
}


export const resendActivation = (deviceId) => {
    return {
        type: RESEND_ACTIVATION,
        payload: {
            deviceId
        }
    }
}

export const resendActivationSuccess = () => {
    return {
        type: RESEND_ACTIVATION_SUCCESS
    }
}

export const resendActivationFailure = () => {
    return {
        type: RESEND_ACTIVATION_FAILURE
    }
}
