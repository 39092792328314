import { CREATE_GROUP, DELETE_GROUP, FETCH_GROUP, FETCH_GROUPS, FETCH_GROUPS_FAILURE, FETCH_GROUPS_FINAL, FETCH_GROUPS_SUCCESS, FETCH_GROUP_FAILURE, FETCH_GROUP_FINAL, FETCH_GROUP_SUCCESS, UPDATE_GROUP } from '../constants/groupsTypes';
import { CREATE_USER_FAILURE, CREATE_USER_SUCCESS, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from '../constants/usersTypes';

const INIT_STATE = {
    groups: [],
    group: {},
    groupsLoading: false,
    groupLoading: false,
    totalCount: 0,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_GROUP: {
            return {
                ...state,
                groupLoading: true
            }
        }
        case FETCH_GROUP_SUCCESS: {
            return {
                ...state,
                group: action.payload.data
            }
        }
        case FETCH_GROUP_FAILURE:
        case FETCH_GROUP_FINAL: {
            return {
                ...state,
                groupLoading: false
            }
        }

        case CREATE_GROUP:
        case UPDATE_GROUP:
        case DELETE_GROUP: {
            return {
                ...state,
                groupLoading: true
            }
        }

        case FETCH_GROUPS: {
            return {
                ...state,
                groupsLoading: true
            }
        }

        case FETCH_GROUPS_SUCCESS: {
            return {
                ...state,
                groups: action.payload.data,
                totalCount: action.payload.data.length
            }
        }

        case FETCH_GROUPS_FAILURE:
        case FETCH_GROUPS_FINAL:
        case CREATE_USER_SUCCESS:
        case CREATE_USER_FAILURE:
        case UPDATE_USER_SUCCESS:
        case UPDATE_USER_FAILURE:
        case DELETE_USER_SUCCESS:
        case DELETE_USER_FAILURE: {
            return {
                ...state,
                groupsLoading: false
            }
        }

        default: return state;
    }
}