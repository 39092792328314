import { combineReducers } from 'redux';

import notificationReducer from './notification';
import tenantReducer from './tenants';
import userReducer from './users';
import groupReducer from './groups';
import deviceReducer from './devices';
import configurationReducer from './configuration';
import tagCategoryReducer from './tagCategories';
import tagLocationReducer from './tagLocations';
import tagContentReducer from './tagContents';
import tagReducer from './tags';

const reducers = combineReducers({
  notification: notificationReducer,
  tenant: tenantReducer,
  user: userReducer,
  group: groupReducer,
  device: deviceReducer,
  configuration: configurationReducer,
  tagCategory: tagCategoryReducer,
  tagLocation: tagLocationReducer,
  tagContent: tagContentReducer,
  tag: tagReducer,
});

export default reducers;