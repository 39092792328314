export const FETCH_TAG_LOCATIONS = 'fetch_tag_locations';
export const FETCH_TAG_LOCATIONS_SUCCESS = 'fetch_tag_locations_success';
export const FETCH_TAG_LOCATIONS_FAILURE = 'fetch_tag_locations_failure';
export const FETCH_TAG_LOCATIONS_FINAL = 'fetch_tag_locations_final';

export const FETCH_TAG_LOCATION = 'fetch_tag_location';
export const FETCH_TAG_LOCATION_SUCCESS = 'fetch_tag_location_success';
export const FETCH_TAG_LOCATION_FAILURE = 'fetch_tag_location_failure';
export const FETCH_TAG_LOCATION_FINAL = 'fetch_tag_location_final';

export const CREATE_TAG_LOCATION = 'create_tag_location';
export const CREATE_TAG_LOCATION_SUCCESS = 'create_tag_location_success';
export const CREATE_TAG_LOCATION_FAILURE = 'create_tag_location_failure';

export const UPDATE_TAG_LOCATION = 'update_tag_location';
export const UPDATE_TAG_LOCATION_SUCCESS = 'update_tag_location_success';
export const UPDATE_TAG_LOCATION_FAILURE = 'update_tag_location_failure';

export const DELETE_TAG_LOCATION = 'delete_tag_location';
export const DELETE_TAG_LOCATION_SUCCESS = 'delete_tag_location_success';
export const DELETE_TAG_LOCATION_FAILURE = 'delete_tag_location_failure';