import {
    FETCH_GROUP_CONFIGURATION,
    FETCH_GROUP_CONFIGURATION_SUCCESS,
    FETCH_GROUP_CONFIGURATION_FAILURE,
    FETCH_GROUP_CONFIGURATION_FINAL,
    FETCH_CONFIGURATION,
    FETCH_CONFIGURATION_FAILURE,
    FETCH_CONFIGURATION_FINAL,
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_SCHEMAS,
    FETCH_SCHEMAS_FAILURE,
    FETCH_SCHEMAS_FINAL,
    FETCH_SCHEMAS_SUCCESS,
    UPDATE_GROUP_CONFIGURATION,
    UPDATE_GROUP_CONFIGURATION_FAILURE,
    UPDATE_GROUP_CONFIGURATION_SUCCESS,
    UPDATE_TENANT_CONFIGURATION,
    UPDATE_TENANT_CONFIGURATION_FAILURE,
    UPDATE_TENANT_CONFIGURATION_SUCCESS,
    DELETE_TENANT_CONFIGURATION,
    DELETE_GROUP_CONFIGURATION,
    DELETE_TENANT_CONFIGURATION_SUCCESS,
    DELETE_TENANT_CONFIGURATION_FAILURE,
    DELETE_GROUP_CONFIGURATION_SUCCESS,
    DELETE_GROUP_CONFIGURATION_FAILURE,
    FETCH_DEVICE_CONFIGURATION,
    FETCH_DEVICE_CONFIGURATION_SUCCESS,
    FETCH_DEVICE_CONFIGURATION_FAILURE,
    UPDATE_DEVICE_CONFIGURATION,
    UPDATE_DEVICE_CONFIGURATION_SUCCESS,
    UPDATE_DEVICE_CONFIGURATION_FAILURE,
    DELETE_DEVICE_CONFIGURATION,
    DELETE_DEVICE_CONFIGURATION_SUCCESS,
    DELETE_DEVICE_CONFIGURATION_FAILURE,
    FETCH_DEVICE_CONFIGURATION_FINAL,
    FETCH_TENANT_SCHEMAS,
    FETCH_TENANT_SCHEMAS_SUCCESS,
    FETCH_TENANT_SCHEMAS_FAILURE,
    FETCH_TENANT_SCHEMAS_FINAL,
    UPDATE_TENANT_SCHEMAS,
    UPDATE_TENANT_SCHEMAS_SUCCESS,
    UPDATE_TENANT_SCHEMAS_FAILURE,
    CREATE_SCHEMA,
    CREATE_SCHEMA_FAILURE,
    CREATE_SCHEMA_SUCCESS,
    UPDATE_SCHEMA,
    UPDATE_SCHEMA_FAILURE,
    UPDATE_SCHEMA_SUCCESS,
    DELETE_SCHEMA,
    DELETE_SCHEMA_FAILURE,
    DELETE_SCHEMA_SUCCESS
} from '../constants/configurationsTypes';

export const fetchSchemas = () => {
    return {
        type: FETCH_SCHEMAS
    }
}

export const fetchSchemasSuccess = (schema) => {
    return {
        type: FETCH_SCHEMAS_SUCCESS,
        payload: schema
    }
}

export const fetchSchemasFailure = () => {
    return {
        type: FETCH_SCHEMAS_FAILURE
    }
}

export const fetchSchemasFinal = () => {
    return {
        type: FETCH_SCHEMAS_FINAL
    }
}

export const createSchema = (schema) => {
    return {
        type: CREATE_SCHEMA,
        payload: {
            schema
        }
    }
}

export const createSchemaSuccess = (schema) => {
    return {
        type: CREATE_SCHEMA_SUCCESS,
        payload: schema
    }
}

export const createSchemaFailure = () => {
    return {
        type: CREATE_SCHEMA_FAILURE
    }
}

export const updateSchema = (schemaId, schema, tenantId) => {
    return {
        type: UPDATE_SCHEMA,
        payload: {
            schemaId, schema, tenantId
        }
    }
}

export const updateSchemaSuccess = () => {
    return {
        type: UPDATE_SCHEMA_SUCCESS,
    }
}

export const updateSchemaFailure = () => {
    return {
        type: UPDATE_SCHEMA_FAILURE
    }
}

export const deleteSchema = (schemaId, tenantId) => {
    return {
        type: DELETE_SCHEMA,
        payload: {
            schemaId, tenantId
        }
    }
}

export const deleteSchemaSuccess = () => {
    return {
        type: DELETE_SCHEMA_SUCCESS,
    }
}

export const deleteSchemaFailure = () => {
    return {
        type: DELETE_SCHEMA_FAILURE
    }
}


export const fetchTenantSchemas = (tenantId) => {
    return {
        type: FETCH_TENANT_SCHEMAS, 
        payload: {
            tenantId
        }
    }
}

export const fetchTenantSchemasSuccess = (schema) => {
    return {
        type: FETCH_TENANT_SCHEMAS_SUCCESS,
        payload: schema
    }
}

export const fetchTenantSchemasFailure = () => {
    return {
        type: FETCH_TENANT_SCHEMAS_FAILURE
    }
}

export const fetchTenantSchemasFinal = () => {
    return {
        type: FETCH_TENANT_SCHEMAS_FINAL
    }
}

export const updateTenantSchemas = (tenantId, enabledSchemas, keepConfigurations) => {
    return {
        type: UPDATE_TENANT_SCHEMAS,
        payload: {
            tenantId, enabledSchemas, keepConfigurations
        }
    }
}

export const updateTenantSchemasSuccess = () => {
    return {
        type: UPDATE_TENANT_SCHEMAS_SUCCESS
    }
}

export const updateTenantSchemasFailure = () => {
    return {
        type: UPDATE_TENANT_SCHEMAS_FAILURE
    }
}

export const fetchConfiguration = (tenantId) => {
    return {
        type: FETCH_CONFIGURATION,
        payload: {
            tenantId
        }
    }
}

export const fetchConfigurationSuccess = (configuration) => {
    return {
        type: FETCH_CONFIGURATION_SUCCESS,
        payload: configuration
    }
}

export const fetchConfigurationFailure = () => {
    return {
        type: FETCH_CONFIGURATION_FAILURE
    }
}

export const fetchConfigurationFinal = () => {
    return {
        type: FETCH_CONFIGURATION_FINAL
    }
}

export const fetchGroupConfiguration = (groupId, tenantId) => {
    return {
        type: FETCH_GROUP_CONFIGURATION,
        payload: {
            tenantId, groupId
        }
    }
}

export const fetchGroupConfigurationSuccess = (configuration) => {
    return {
        type: FETCH_GROUP_CONFIGURATION_SUCCESS,
        payload: configuration
    }
}

export const fetchGroupConfigurationFailure = () => {
    return {
        type: FETCH_GROUP_CONFIGURATION_FAILURE
    }
}

export const fetchGroupConfigurationFinal = () => {
    return {
        type: FETCH_GROUP_CONFIGURATION_FINAL
    }
}

export const updateTenantConfiguration = (tenantId, configuration) => {
    return {
        type: UPDATE_TENANT_CONFIGURATION,
        payload: {
            tenantId, configuration
        }
    }
}

export const updateTenantConfigurationSuccess = () => {
    return {
        type: UPDATE_TENANT_CONFIGURATION_SUCCESS
    }
}

export const updateTenantConfigurationFailure = () => {
    return {
        type: UPDATE_TENANT_CONFIGURATION_FAILURE
    }
}

export const updateGroupConfiguration = (groupId, configuration) => {
    return {
        type: UPDATE_GROUP_CONFIGURATION,
        payload: {
            groupId, configuration
        }
    }
}

export const updateGroupConfigurationSuccess = () => {
    return {
        type: UPDATE_GROUP_CONFIGURATION_SUCCESS
    }
}

export const updateGroupConfigurationFailure = () => {
    return {
        type: UPDATE_GROUP_CONFIGURATION_FAILURE
    }
}

export const deleteTenantConfiguration = (tenantId) => {
    return {
        type: DELETE_TENANT_CONFIGURATION,
        payload: {
            tenantId
        }
    }
}

export const deleteTenantConfigurationSuccess = () => {
    return {
        type: DELETE_TENANT_CONFIGURATION_SUCCESS
    }
}

export const deleteTenantConfigurationFailure = () => {
    return {
        type: DELETE_TENANT_CONFIGURATION_FAILURE
    }
}

export const deleteGroupConfiguration = (groupId, tenantId) => {
    return {
        type: DELETE_GROUP_CONFIGURATION,
        payload: {
            groupId, tenantId
        }
    }
}

export const deleteGroupConfigurationSuccess = () => {
    return {
        type: DELETE_GROUP_CONFIGURATION_SUCCESS
    }
}

export const deleteGroupConfigurationFailure = () => {
    return {
        type: DELETE_GROUP_CONFIGURATION_FAILURE
    }
}

export const fetchDeviceConfiguration = (deviceId, tenantId, groupId) => {
    return {
        type: FETCH_DEVICE_CONFIGURATION,
        payload: {
            tenantId, deviceId, groupId
        }
    }
}

export const fetchDeviceConfigurationSuccess = (configuration) => {
    return {
        type: FETCH_DEVICE_CONFIGURATION_SUCCESS,
        payload: configuration
    }
}

export const fetchDeviceConfigurationFailure = () => {
    return {
        type: FETCH_DEVICE_CONFIGURATION_FAILURE
    }
}

export const fetchDeviceConfigurationFinal = () => {
    return {
        type: FETCH_DEVICE_CONFIGURATION_FINAL
    }
}


export const updateDeviceConfiguration = (deviceId, configuration) => {
    return {
        type: UPDATE_DEVICE_CONFIGURATION,
        payload: {
            deviceId, configuration
        }
    }
}

export const updateDeviceConfigurationSuccess = () => {
    return {
        type: UPDATE_DEVICE_CONFIGURATION_SUCCESS
    }
}

export const updateDeviceConfigurationFailure = () => {
    return {
        type: UPDATE_DEVICE_CONFIGURATION_FAILURE
    }
}


export const deleteDeviceConfiguration = (deviceId, tenantId, groupId) => {
    return {
        type: DELETE_DEVICE_CONFIGURATION,
        payload: {
            deviceId, tenantId, groupId
        }
    }
}

export const deleteDeviceConfigurationSuccess = () => {
    return {
        type: DELETE_DEVICE_CONFIGURATION_SUCCESS
    }
}

export const deleteDeviceConfigurationFailure = () => {
    return {
        type: DELETE_DEVICE_CONFIGURATION_FAILURE
    }
}

