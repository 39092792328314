import React from 'react';
import PropTypes from 'prop-types';
import axios from './customAxios';

import useInterval from './useInterval';

const authContext = React.createContext();

/**
 * Creates and returns a provider component that can be used to wrap your app
 * @param {{children: JSX.Element}} [Props] 
 * @returns {JSX.Element} The Authentication Provider
 */
export const ProvideAuth = ({ children }) => {
    const auth = useProviderAuth();
    return (
        <authContext.Provider value={auth}>{children}</authContext.Provider>
    );
}
ProvideAuth.propTypes = {
    children: PropTypes.node.isRequired
}

/**
 * Returns the authentication context that can be used to access the user
 * @returns {{
 *  user: object,
 *  login: function (): void,
 *  logout: function (): void,
 *  getUser: function (): object
 * }} The authentication context
 */
export default () => {
    return React.useContext(authContext);
}

/**
 * Provider that creates an auth object and handles state
 * @returns {{
 *  user: object,
 *  login: function (): void,
 *  logout: function (): Promise<void>,
 *  getUser: function (): Promise<object>
 * }}
 */
const useProviderAuth = () => {
    const [user, setUser] = React.useState(null);

    /**
     * On mount checks if the user null, if so, fetches the user
     */
    React.useEffect(() => {
        if (!user) {
            getUser();
        }
    }, []);

    /**
     * Checks for user session validity every 5 minutes
     */
    useInterval(() => {
        checkAuth();
    }, 300000);

    /**
     * Triggers redirect that moves user to the appropriate authentication platform
     */
    const login = () => {
        window.location.href = `${process.env.REACT_APP_AUTH_API_BASE}/Login?redirectTo=${process.env.REACT_APP_AUTH_REDIRECT_BASE}${window.location.pathname}`;
    }

    /**
     * Triggers the logout process that clears the user, and redirects to the login page
     */
    const logout = async () => {
        setUser(null);
        window.location.href = `${process.env.REACT_APP_AUTH_API_BASE}/Logout?redirectTo=${process.env.REACT_APP_AUTH_REDIRECT_BASE}`;
    }

    /**
     * React hook that fetches the user info from the redux store
     * @returns { object } The user object if it exists, void if otherwise
     */
    const getUser = async () => {
        try {
            if (!user) {
                const { data, status, headers } = await axios.get(`${process.env.REACT_APP_AUTH_API_BASE}/Userinfo`, {
                    withCredentials: true
                });
                if (data) {
                    setUser(data);
                } else {
                    console.error("Unexpected response from auth server", data, status, headers);
                }
            } else {
                return user;
            }
        } catch {
            // If the axios request returns a 'failed' state, the user should be unset and redirected to the login page
            setUser(null);
            login();
        }
    }

    /**
     * Checks if the user cookie is still valid
     */
    const checkAuth = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_AUTH_API_BASE}/Userinfo`, {
                withCredentials: true
            });
        } catch {
            // If the axios request returns a 'failed' state, the user should be unset and redirected to the login page
            setUser(null);
            login();
        }
    }

    return {
        user,
        login,
        logout,
        getUser
    }
}
