/* eslint-disable no-unused-vars */
// Lib imports
import React from 'react';

// Project imports
import Main from './Main';
import useAuth from '../utils/useAuth';
import AuthLoader from '../components/AuthLoader';

const Router = () => {

    const auth = useAuth();

    return (
        <>
            {auth.user ? (
                <Main />
            ) : (
                <AuthLoader />
            )}
        </>
    )
}

export default Router;