import { FETCH_TAG_CONTENTS, FETCH_TAG_CONTENTS_FAILURE, FETCH_TAG_CONTENTS_FINAL, FETCH_TAG_CONTENTS_SUCCESS, DELETE_TAG_CONTENT, DELETE_TAG_CONTENT_FAILURE, DELETE_TAG_CONTENT_SUCCESS, UPDATE_TAG_CONTENT, UPDATE_TAG_CONTENT_FAILURE, UPDATE_TAG_CONTENT_SUCCESS, ADD_TAG_CONTENT, ADD_TAG_CONTENT_SUCCESS, ADD_TAG_CONTENT_FAILURE, FETCH_TAG_CONTENT_ITEM, FETCH_TAG_CONTENT_ITEM_SUCCESS, FETCH_TAG_CONTENT_ITEM_FAILURE, FETCH_TAG_CONTENT_ITEM_FINAL } from '../constants/tagContentTypes';

export const fetchTagContents = (tagId) => {
    return {
        type: FETCH_TAG_CONTENTS,
        payload: {
            tagId
        }
    }
}

export const fetchTagContentsSuccess = (tagContents) => {
    return {
        type: FETCH_TAG_CONTENTS_SUCCESS,
        payload: tagContents
    }
}

export const fetchTagContentsFailure = () => {
    return {
        type: FETCH_TAG_CONTENTS_FAILURE
    }
}

export const fetchTagContentsFinal = () => {
    return {
        type: FETCH_TAG_CONTENTS_FINAL
    }
}

export const fetchTagContentItem = (tagContentId) => {
    return {
        type: FETCH_TAG_CONTENT_ITEM,
        payload: {
            tagContentId
        }
    }
}

export const fetchTagContentItemSuccess = (tagContentItem) => {
    return {
        type: FETCH_TAG_CONTENT_ITEM_SUCCESS,
        payload: tagContentItem
    }
}

export const fetchTagContentItemFailure = () => {
    return {
        type: FETCH_TAG_CONTENT_ITEM_FAILURE
    }
}

export const fetchTagContentItemFinal = () => {
    return {
        type: FETCH_TAG_CONTENT_ITEM_FINAL
    }
}

export const createTagContent = (tagId, tagContent) => {
    return {
        type: ADD_TAG_CONTENT,
        payload: {
            tagId,
            tagContent
        }
    }
}

export const createTagContentSuccess = () => {
    return {
        type: ADD_TAG_CONTENT_SUCCESS
    }
}

export const createTagContentFailure = () => {
    return {
        type: ADD_TAG_CONTENT_FAILURE
    }
}

export const updateTagContent = (tagId, tagContentItemId, tagContent) => {
    return {
        type: UPDATE_TAG_CONTENT,
        payload: {
            tagId,
            tagContentItemId,
            tagContent
        }
    }
}

export const updateTagContentSuccess = () => {
    return {
        type: UPDATE_TAG_CONTENT_SUCCESS
    }
}

export const updateTagContentFailure = () => {
    return {
        type: UPDATE_TAG_CONTENT_FAILURE
    }
}

export const deleteTagContent = (tagId, tagContentId) => {
    return {
        type: DELETE_TAG_CONTENT,
        payload: {
            tagId,
            tagContentId
        }
    }
}

export const deleteTagContentSuccess = () => {
    return {
        type: DELETE_TAG_CONTENT_SUCCESS
    }
}

export const deleteTagContentFailure = () => {
    return {
        type: DELETE_TAG_CONTENT_FAILURE
    }
}