import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_GROUP, DELETE_GROUP, FETCH_GROUP, FETCH_GROUPS, UPDATE_GROUP } from '../constants/groupsTypes';
import { fetchGroups as fetchGroupsAction, fetchGroupFailure, fetchGroupFinal, fetchGroupsFailure, fetchGroupsFinal, fetchGroupsSuccess, fetchGroupSuccess, notifyOpen } from '../actions';

function fetchGroupRequest(groupId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Groups/${groupId}`);
}

function* fetchGroup({ payload }) {
    try {
        const { data } = yield call(fetchGroupRequest, payload.groupId);

        yield put(fetchGroupSuccess(data));
    } catch (error) {
        yield put(fetchGroupFailure());
    } finally {
        yield put(fetchGroupFinal());
    }
}

export function* watchFetchGroup() {
    yield takeLatest(FETCH_GROUP, fetchGroup);
}


function fetchGroupsRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Groups`);
}

function* fetchGroups({ payload }) {
    try {
        const { data, headers } = yield call(fetchGroupsRequest, payload.tenantId);

        yield put(fetchGroupsSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchGroupsFailure());
    } finally {
        yield put(fetchGroupsFinal());
    }
}

export function* watchFetchGroups() {
    yield takeLatest(FETCH_GROUPS, fetchGroups);
}


function createGroupRequest(tenantId, groupName) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Groups`, {
        name: groupName
    });
}

function* createGroup({ payload }) {
    try {
        yield call(createGroupRequest, payload.tenantId, payload.groupName);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created group'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create group'
        }));
    } finally {
        yield put(fetchGroupsAction(payload.tenantId))
    }
}

export function* watchCreateGroup() {
    yield takeLatest(CREATE_GROUP, createGroup);
}


function updateGroupRequest(groupId, groupName) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/Groups/${groupId}`, {
        name: groupName
    });
}

function* updateGroup({ payload }) {
    try {
        yield call(updateGroupRequest, payload.groupId, payload.groupName);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully updated group'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to update group'
        }));
    } finally {
        yield put(fetchGroupsAction(payload.tenantId))
    }
}

export function* watchUpdateGroup() {
    yield takeLatest(UPDATE_GROUP, updateGroup);
}


function deleteGroupRequest(groupId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/Groups/${groupId}`);
}

function* deleteGroup({ payload }) {
    try {
        yield call(deleteGroupRequest, payload.groupId);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully deleted group'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to delete group'
        }));
    } finally {
        yield put(fetchGroupsAction(payload.tenantId))
    }
}

export function* watchDeleteGroup() {
    yield takeLatest(DELETE_GROUP, deleteGroup);
}


export default function* rootSaga() {
    yield all([
        fork(watchFetchGroup),
        fork(watchFetchGroups),
        fork(watchCreateGroup),
        fork(watchUpdateGroup),
        fork(watchDeleteGroup)
    ]);
}