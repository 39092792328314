import { CREATE_USER, CREATE_USER_FAILURE, CREATE_USER_SUCCESS, DELETE_USER, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, FETCH_USERS, FETCH_USERS_FAILURE, FETCH_USERS_FINAL, FETCH_USERS_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from '../constants/usersTypes';


export const fetchUsers = (tenantId) => {
    return {
        type: FETCH_USERS,
        payload: {
            tenantId
        }
    }
}

export const fetchUsersSuccess = (users) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users
    }
}

export const fetchUsersFailure = () => {
    return {
        type: FETCH_USERS_FAILURE
    }
}

export const fetchUsersFinal = () => {
    return {
        type: FETCH_USERS_FINAL
    }
}

export const createUser = (tenantId, user) => {
    return {
        type: CREATE_USER,
        payload: {
            tenantId, user
        }
    }
}

export const createUserSuccess = () => {
    return {
        type: CREATE_USER_SUCCESS
    }
}

export const createUserFailure = () => {
    return {
        type: CREATE_USER_FAILURE
    }
}

export const updateUser = (userId, user, tenantId) => {
    return {
        type: UPDATE_USER,
        payload: {
            userId, user, tenantId
        }
    }
}

export const updateUserSuccess = () => {
    return {
        type: UPDATE_USER_SUCCESS
    }
}

export const updateUserFailure = () => {
    return {
        type: UPDATE_USER_FAILURE
    }
}

export const deleteUser = (userId, tenantId) => {
    return {
        type: DELETE_USER,
        payload: {
            userId, tenantId
        }
    }
}

export const deleteUserSuccess = () => {
    return {
        type: DELETE_USER_SUCCESS
    }
}

export const deleteUserFailure = () => {
    return {
        type: DELETE_USER_FAILURE
    }
}