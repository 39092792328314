import { NOTIFY_CLOSE, NOTIFY_OPEN } from '../constants/notificationTypes';

const INIT_STATE = {
    open: false,
    type: 'info',
    text: ''
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case NOTIFY_OPEN: {
            return {
                ...state,
                open: true,
                type: action.payload.type,
                text: action.payload.text
            }
        }
        case NOTIFY_CLOSE: {
            return {
                ...state,
                open: false
            }
        }
        default: return state;
    }
}