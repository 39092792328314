import { FETCH_TAG, FETCH_TAGS, FETCH_TAGS_FAILURE, FETCH_TAGS_FINAL, FETCH_TAGS_SUCCESS, FETCH_TAG_FAILURE, FETCH_TAG_FINAL, FETCH_TAG_SUCCESS } from '../constants/tagTypes';

const INIT_STATE = {
    tags: [],
    tagsLoading: false,
    tag: {},
    tagLoading: false
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TAGS: {
            return {
                ...state,
                tagsLoading: true
            }
        }
        case FETCH_TAGS_SUCCESS: {
            return {
                ...state,
                tags: action.payload.data
            }
        }
        case FETCH_TAGS_FAILURE:
        case FETCH_TAGS_FINAL: {
            return {
                ...state,
                tagsLoading: false
            }
        }

        case FETCH_TAG: {
            return {
                ...state,
                tagLoading: true
            }
        }
        case FETCH_TAG_SUCCESS: {
            return {
                ...state,
                tag: action.payload.data
            }
        }
        case FETCH_TAG_FAILURE:
        case FETCH_TAG_FINAL: {
            return {
                ...state,
                tagLoading: false
            }
        }

        default: return state;
    }
}