import { CREATE_GROUP, CREATE_GROUP_FAILURE, CREATE_GROUP_SUCCESS, DELETE_GROUP, DELETE_GROUP_FAILURE, DELETE_GROUP_SUCCESS, FETCH_GROUP, FETCH_GROUPS, FETCH_GROUPS_FAILURE, FETCH_GROUPS_FINAL, FETCH_GROUPS_SUCCESS, FETCH_GROUP_FAILURE, FETCH_GROUP_FINAL, FETCH_GROUP_SUCCESS, UPDATE_GROUP, UPDATE_GROUP_FAILURE, UPDATE_GROUP_SUCCESS } from '../constants/groupsTypes';


export const fetchGroups = (tenantId) => {
    return {
        type: FETCH_GROUPS, 
        payload: {
            tenantId
        }
    }
}

export const fetchGroupsSuccess = (groups) => {
    return {
        type: FETCH_GROUPS_SUCCESS,
        payload: groups
    }
}

export const fetchGroupsFailure = () => {
    return {
        type: FETCH_GROUPS_FAILURE
    }
}

export const fetchGroupsFinal = () => {
    return {
        type: FETCH_GROUPS_FINAL
    }
}


export const fetchGroup = (groupId) => {
    return {
        type: FETCH_GROUP,
        payload: {
            groupId
        }
    }
}

export const fetchGroupSuccess = (group) => {
    return {
        type: FETCH_GROUP_SUCCESS,
        payload: group
    }
}

export const fetchGroupFailure = () => {
    return {
        type: FETCH_GROUP_FAILURE
    }
}

export const fetchGroupFinal = () => {
    return {
        type: FETCH_GROUP_FINAL
    }
}


export const createGroup = (tenantId, groupName) => {
    return {
        type: CREATE_GROUP,
        payload: {
            tenantId, groupName
        }
    }
}

export const createGroupSuccess = () => {
    return {
        type: CREATE_GROUP_SUCCESS
    }
}

export const createGroupFailure = () => {
    return {
        type: CREATE_GROUP_FAILURE
    }
}


export const updateGroup = (tenantId, groupId, groupName) => {
    return {
        type: UPDATE_GROUP,
        payload: {
            tenantId, groupId, groupName
        }
    }
}

export const updateGroupSuccess = () => {
    return {
        type: UPDATE_GROUP_SUCCESS
    }
}

export const updateGroupFailure = () => {
    return {
        type: UPDATE_GROUP_FAILURE
    }
}


export const deleteGroup = (tenantId, groupId) => {
    return {
        type: DELETE_GROUP,
        payload: {
            tenantId, groupId
        }
    }
}

export const deleteGroupSuccess = () => {
    return {
        type: DELETE_GROUP_SUCCESS
    }
}

export const deleteGroupFailure = () => {
    return {
        type: DELETE_GROUP_FAILURE
    }
}
