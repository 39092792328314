//Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { init as initApm } from '@elastic/apm-rum';

//Project imports
import Router from './containers/router';
import { mainTheme } from './utils/customMUI';
import Notification from './components/Notification';
import axiosInstance from './utils/customAxios';
import { ProvideAuth } from './utils/useAuth';

const App = (props) => {

  initApm({
    serviceName: 'platform-web',
    serverUrl: process.env.REACT_APP_ELASTIC_APM_URL,
    serviceVersion: '1.0',
    environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
    active: process.env.REACT_APP_ELASTIC_APM_ENABLED === 'true',
    distributedTracingOrigins: process.env.REACT_APP_ELASTIC_APM_ORIGINS ? process.env.REACT_APP_ELASTIC_APM_ORIGINS.split(',') : [],
    propagateTracestate: true
  });

  axiosInstance.defaults.withCredentials = true;

  return (
    <Provider store={props.store}>
      <ThemeProvider theme={mainTheme}>
        <Notification />
        <ProvideAuth>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ProvideAuth>
      </ThemeProvider>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default App
