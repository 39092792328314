import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_USER, DELETE_USER, FETCH_USERS, UPDATE_USER } from '../constants/usersTypes';
import { fetchUsers as fetchUsersAction, fetchUsersSuccess, fetchUsersFailure, fetchUsersFinal, notifyOpen } from '../actions';

export function fetchUsersRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/users`);
}

function* fetchUsers({ payload }) {
    try {
        const { data, headers } = yield call(fetchUsersRequest, payload.tenantId);
        
        yield put(fetchUsersSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchUsersFailure());
    } finally {
        yield put(fetchUsersFinal());
    }
}

export function* watchFetchUsers() {
    yield takeLatest(FETCH_USERS, fetchUsers);
}


function createUserRequest(tenantId, user) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/users`, {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username, 
        email: user.email,
        role: user.roles[0]
    });
}

function* createUser({ payload }) {
    try {
        yield call(createUserRequest, payload.tenantId, payload.user);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created user'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create user'
        }));
    } finally {
        yield put(fetchUsersAction(payload.tenantId))
    }
}

export function* watchCreateUser() {
    yield takeLatest(CREATE_USER, createUser);
}


function updateUserRequest(userId, user) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/users/${userId}`, {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username, 
        email: user.email, 
        role: user.roles[0]
    });
}

function* updateUser({ payload }) {
    try {
        yield call(updateUserRequest, payload.userId, payload.user);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully updated user'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to update user'
        }));
    } finally {
        yield put(fetchUsersAction(payload.tenantId))
    }
}

export function* watchUpdateUser() {
    yield takeLatest(UPDATE_USER, updateUser);
}


function deleteUserRequest(userId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/users/${userId}`);
}

function* deleteUser({ payload }) {
    try {
        yield call(deleteUserRequest, payload.userId);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully deleted user'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to delete user'
        }));
    } finally {
        yield put(fetchUsersAction(payload.tenantId))
    }
}

export function* watchDeleteUser() {
    yield takeLatest(DELETE_USER, deleteUser);
}


export default function* rootSaga() {
    yield all([
        fork(watchFetchUsers),
        fork(watchCreateUser),
        fork(watchUpdateUser),
        fork(watchDeleteUser)
    ])
}