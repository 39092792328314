import { CREATE_TAG, CREATE_TAG_FAILURE, CREATE_TAG_SUCCESS, DELETE_TAG, DELETE_TAG_FAILURE, DELETE_TAG_SUCCESS, FETCH_TAG, FETCH_TAGS, FETCH_TAGS_FAILURE, FETCH_TAGS_FINAL, FETCH_TAGS_SUCCESS, FETCH_TAG_FAILURE, FETCH_TAG_FINAL, FETCH_TAG_SUCCESS, UPDATE_TAG, UPDATE_TAG_FAILURE, UPDATE_TAG_SUCCESS } from '../constants/tagTypes';

export const fetchTags = (tenantId) => {
    return {
        type: FETCH_TAGS,
        payload: {
            tenantId
        }
    }
}

export const fetchTagsSuccess = (tags) => {
    return {
        type: FETCH_TAGS_SUCCESS,
        payload: tags
    }
}

export const fetchTagsFailure = () => {
    return {
        type: FETCH_TAGS_FAILURE
    }
}

export const fetchTagsFinal = () => {
    return {
        type: FETCH_TAGS_FINAL
    }
}

export const fetchTag = (tagId) => {
    return {
        type: FETCH_TAG,
        payload: {
            tagId
        }
    }
}

export const fetchTagSuccess = (tag) => {
    return {
        type: FETCH_TAG_SUCCESS,
        payload: tag
    }
}

export const fetchTagFailure = () => {
    return {
        type: FETCH_TAG_FAILURE
    }
}

export const fetchTagFinal = () => {
    return {
        type: FETCH_TAG_FINAL
    }
}

export const createTag = (tenantId, tag) => {
    return {
        type: CREATE_TAG,
        payload: {
            tenantId, tag
        }
    }
}

export const createTagSuccess = () => {
    return {
        type: CREATE_TAG_SUCCESS
    }
}

export const createTagFailure = () => {
    return {
        type: CREATE_TAG_FAILURE
    }
}

export const updateTag = (tenantId, tag) => {
    return {
        type: UPDATE_TAG,
        payload: {
            tenantId, tag
        }
    }
}

export const updateTagSuccess = () => {
    return {
        type: UPDATE_TAG_SUCCESS
    }
}

export const updateTagFailure = () => {
    return {
        type: UPDATE_TAG_FAILURE
    }
}

export const deleteTag = (tenantId, tagId) => {
    return {
        type: DELETE_TAG,
        payload: {
            tenantId, tagId
        }
    }
}

export const deleteTagSuccess = () => {
    return {
        type: DELETE_TAG_SUCCESS
    }
}

export const deleteTagFailure = () => {
    return {
        type: DELETE_TAG_FAILURE
    }
}
