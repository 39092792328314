//Lib imports
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from 'redux-saga';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

//Project imports
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import rootSaga from "./sagas";
import rootReducer from './reducers';
import './i18n';

export const muiCache = createCache({
  key: 'mui',
  prepend: true
});

let composeEnhancers = compose;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

if (window.Cypress) {
  window.store = store;
}

sagaMiddleware.run(rootSaga);

createRoot(document.getElementById('root')).render(
  <CacheProvider value={muiCache}>
    <App store={store} />
  </CacheProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
