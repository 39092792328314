import {
    CREATE_TENANT,
    CREATE_TENANT_FAILURE,
    CREATE_TENANT_SUCCESS,
    DELETE_TENANT,
    DELETE_TENANT_FAILURE,
    DELETE_TENANT_SUCCESS,
    FETCH_TENANT,
    FETCH_TENANTS,
    FETCH_TENANTS_FAILURE,
    FETCH_TENANTS_FINAL, 
    FETCH_TENANTS_SUCCESS, 
    FETCH_TENANT_FAILURE, 
    FETCH_TENANT_FINAL, 
    FETCH_TENANT_SUCCESS, 
    UPDATE_TENANT, 
    UPDATE_TENANT_FAILURE, 
    UPDATE_TENANT_SUCCESS,
    FETCH_TENANTS_WITH_SALES_DATA,
    FETCH_TENANTS_WITH_SALES_DATA_SUCCESS,
    FETCH_TENANTS_WITH_SALES_DATA_FAILURE,
    FETCH_TENANTS_WITH_SALES_DATA_FINAL
} from "../constants/tenantsTypes"


export const fetchTenants = (withUsers = false) => {
    return {
        type: FETCH_TENANTS,
        payload: {
            withUsers
        }
    }
}

export const fetchTenantsSuccess = (tenants) => {
    return {
        type: FETCH_TENANTS_SUCCESS,
        payload: tenants
    }
}

export const fetchTenantsFailure = () => {
    return {
        type: FETCH_TENANTS_FAILURE,
    }
}

export const fetchTenantsFinal = () => {
    return {
        type: FETCH_TENANTS_FINAL
    }
}

export const fetchTenant = (tenantId) => {
    return {
        type: FETCH_TENANT,
        payload: {
            tenantId
        }
    }
}

export const fetchTenantSuccess = (tenant) => {
    return {
        type: FETCH_TENANT_SUCCESS,
        payload: tenant
    }
}

export const fetchTenantFailure = () => {
    return {
        type: FETCH_TENANT_FAILURE
    }
}

export const fetchTenantFinal = () => {
    return {
        type: FETCH_TENANT_FINAL
    }
}

export const fetchTenantsWithSalesData = () => {
    return {
        type: FETCH_TENANTS_WITH_SALES_DATA
    }
}

export const fetchTenantsWithSalesDataSuccess = (tenantsWithSalesData) => {
    return {
        type: FETCH_TENANTS_WITH_SALES_DATA_SUCCESS,
        payload: tenantsWithSalesData
    }
}

export const fetchTenantsWithSalesDataFailure = () => {
    return {
        type: FETCH_TENANTS_WITH_SALES_DATA_FAILURE
    }
}

export const fetchTenantsWithSalesDataFinal = () => {
    return {
        type: FETCH_TENANTS_WITH_SALES_DATA_FINAL
    }
}

export const createTenant = (tenant, modules) => {
    return {
        type: CREATE_TENANT,
        payload: {
            tenant, modules
        }
    }
}

export const createTenantSuccess = () => {
    return {
        type: CREATE_TENANT_SUCCESS
    }
}

export const createTenantFailure = () => {
    return {
        type: CREATE_TENANT_FAILURE
    }
}

export const updateTenant = (id, data) => {
    return {
        type: UPDATE_TENANT,
        payload: {
            id, data
        }
    }
}

export const updateTenantSuccess = () => {
    return {
        type: UPDATE_TENANT_SUCCESS
    }
}

export const updateTenantFailure = () => {
    return {
        type: UPDATE_TENANT_FAILURE
    }
}

export const deleteTenant = (tenantId) => {
    return {
        type: DELETE_TENANT,
        payload: {
            tenantId
        }
    }
}

export const deleteTenantSuccess = () => {
    return {
        type: DELETE_TENANT_SUCCESS
    }
}

export const deleteTenantFailure = () => {
    return {
        type: DELETE_TENANT_FAILURE
    }
}
