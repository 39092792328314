export const FETCH_USERS = 'fetch_users';
export const FETCH_USERS_SUCCESS = 'fetch_users_success';
export const FETCH_USERS_FAILURE = 'fetch_users_failure';
export const FETCH_USERS_FINAL = 'fetch_users_final';

export const CREATE_USER = 'create_user';
export const CREATE_USER_SUCCESS = 'create_user_success';
export const CREATE_USER_FAILURE = 'create_user_failure';

export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_USER_FAILURE = 'update_user_failure';

export const DELETE_USER = 'delete_user';
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_FAILURE = 'delete_user_failure';