export const FETCH_TENANT_DEVICES = 'fetch_tenant_devices';
export const FETCH_TENANT_DEVICES_SUCCESS = 'fetch_tenant_devices_success';
export const FETCH_TENANT_DEVICES_FAILURE = 'fetch_tenant_devices_failure';
export const FETCH_TENANT_DEVICES_FINAL = 'fetch_tenant_devices_final';

export const CREATE_TENANT_DEVICE = 'create_tenant_device';
export const CREATE_TENANT_DEVICE_SUCCESS = 'create_tenant_device_success';
export const CREATE_TENANT_DEVICE_FAILURE = 'create_tenant_device_failure';

export const FETCH_GROUP_DEVICES = 'fetch_group_devices';
export const FETCH_GROUP_DEVICES_SUCCESS = 'fetch_group_devices_success';
export const FETCH_GROUP_DEVICES_FAILURE = 'fetch_group_devices_failure';
export const FETCH_GROUP_DEVICES_FINAL = 'fetch_group_devices_final';

export const CREATE_GROUP_DEVICE = 'create_group_device';
export const CREATE_GROUP_DEVICE_SUCCESS = 'create_group_device_success';
export const CREATE_GROUP_DEVICE_FAILURE = 'create_group_device_failure';

export const UPDATE_DEVICE = 'update_device';
export const UPDATE_DEVICE_SUCCESS = 'update_device_success';
export const UPDATE_DEVICE_FAILURE = 'update_device_failure';

export const DELETE_DEVICE = 'delete_device';
export const DELETE_DEVICE_SUCCESS = 'delete_device_success';
export const DELETE_DEVICE_FAILURE = 'delete_device_failure';

export const REGISTER_DEVICE = 'register_device';
export const REGISTER_DEVICE_SUCCESS = 'register_device_success';
export const REGISTER_DEVICE_FAILURE = 'register_device_failure';

export const VALIDATE_DEVICE = 'validate_device';
export const VALIDATE_DEVICE_SUCCESS = 'validate_device_success';
export const VALIDATE_DEVICE_FAILURE = 'validate_device_failure';

export const FETCH_DEVICE_STATUS = 'fetch_device_status';
export const FETCH_DEVICE_STATUS_SUCCESS = 'fetch_device_status_success';
export const FETCH_DEVICE_STATUS_FAILURE = 'fetch_device_status_failure';
export const FETCH_DEVICE_STATUS_FINAL = 'fetch_device_status_final';

export const FETCH_DEVICE_HEARTBEAT = 'fetch_device_heartbeat';
export const FETCH_DEVICE_HEARTBEAT_SUCCESS = 'fetch_device_heartbeat_success';
export const FETCH_DEVICE_HEARTBEAT_FAILURE = 'fetch_device_heartbeat_failure';
export const FETCH_DEVICE_HEARTBEAT_FINAL = 'fetch_device_heartbeat_final';

export const RESEND_ACTIVATION = 'resend_activation';
export const RESEND_ACTIVATION_SUCCESS = 'resend_activation_success';
export const RESEND_ACTIVATION_FAILURE = 'resend_activation_failure';