import { FETCH_TAG_CONTENTS, FETCH_TAG_CONTENTS_FAILURE, FETCH_TAG_CONTENTS_FINAL, FETCH_TAG_CONTENTS_SUCCESS, DELETE_TAG_CONTENT, DELETE_TAG_CONTENT_FAILURE, DELETE_TAG_CONTENT_SUCCESS, UPDATE_TAG_CONTENT, UPDATE_TAG_CONTENT_FAILURE, UPDATE_TAG_CONTENT_SUCCESS, FETCH_TAG_CONTENT_ITEM, FETCH_TAG_CONTENT_ITEM_SUCCESS, FETCH_TAG_CONTENT_ITEM_FAILURE, FETCH_TAG_CONTENT_ITEM_FINAL } from '../constants/tagContentTypes';

const INIT_STATE = {
    content: [],
    contentItem: {},
    contentLoading: false,
    contentItemLoading: false,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TAG_CONTENTS: {
            return {
                ...state,
                contentLoading: true,
            }
        }
        case FETCH_TAG_CONTENTS_SUCCESS: {
            return {
                ...state,
                content: action.payload.data
            }
        }
        case FETCH_TAG_CONTENTS_FAILURE:
        case FETCH_TAG_CONTENTS_FINAL: {
            return {
                ...state,
                contentLoading: false,
            }
        }

        case FETCH_TAG_CONTENT_ITEM: {
            return {
                ...state,
                contentItemLoading: true
            }
        }
        case FETCH_TAG_CONTENT_ITEM_SUCCESS: {
            return {
                ...state,
                contentItem: action.payload.data
            }
        }
        case FETCH_TAG_CONTENT_ITEM_FAILURE:
        case FETCH_TAG_CONTENT_ITEM_FINAL: {
            return {
                ...state,
                contentItemLoading: false
            }
        }

        case UPDATE_TAG_CONTENT: {
            return {
                ...state,
                contentLoading: true,
            }
        }
        case UPDATE_TAG_CONTENT_SUCCESS:
        case UPDATE_TAG_CONTENT_FAILURE: {
            return {
                ...state,
                contentLoading: false,
            }
        }

        case DELETE_TAG_CONTENT: {
            return {
                ...state,
                contentLoading: true,
            }
        }
        case DELETE_TAG_CONTENT_SUCCESS:
        case DELETE_TAG_CONTENT_FAILURE: {
            return {
                ...state,
                contentLoading: false,
            }
        }

        default: return state;
    }
}