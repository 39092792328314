import { FETCH_TAG_CATEGORY, FETCH_TAG_CATEGORIES, FETCH_TAG_CATEGORIES_FAILURE, FETCH_TAG_CATEGORIES_FINAL, FETCH_TAG_CATEGORIES_SUCCESS, FETCH_TAG_CATEGORY_SUCCESS, FETCH_TAG_CATEGORY_FAILURE, DELETE_TAG_CATEGORY, DELETE_TAG_CATEGORY_FAILURE, DELETE_TAG_CATEGORY_SUCCESS, FETCH_TAG_CATEGORY_FINAL, UPDATE_TAG_CATEGORY, UPDATE_TAG_CATEGORY_FAILURE, UPDATE_TAG_CATEGORY_SUCCESS } from '../constants/tagCategoryTypes';

const INIT_STATE = {
    categories: [],
    categoriesLoading: false,
    category: {},
    categoryLoading: false,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TAG_CATEGORIES: {
            return {
                ...state,
                categoriesLoading: true,
            }
        }
        case FETCH_TAG_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categories: action.payload.data
            }
        }
        case FETCH_TAG_CATEGORIES_FAILURE:
        case FETCH_TAG_CATEGORIES_FINAL: {
            return {
                ...state,
                categoriesLoading: false,
            }
        }

        case FETCH_TAG_CATEGORY: {
            return {
                ...state,
                categoryLoading: true,
            }
        }
        case FETCH_TAG_CATEGORY_SUCCESS: {
            return {
                ...state,
                category: action.payload
            }
        }
        case FETCH_TAG_CATEGORY_FAILURE:
        case FETCH_TAG_CATEGORY_FINAL: {
            return {
                ...state,
                categoryLoading: false,
            }
        }

        case UPDATE_TAG_CATEGORY: {
            return {
                ...state,
                categoriesLoading: true,
            }
        }
        case UPDATE_TAG_CATEGORY_SUCCESS:
        case UPDATE_TAG_CATEGORY_FAILURE: {
            return {
                ...state,
                categoriesLoading: false,
            }
        }

        case DELETE_TAG_CATEGORY: {
            return {
                ...state,
                categoriesLoading: true,
            }
        }
        case DELETE_TAG_CATEGORY_SUCCESS:
        case DELETE_TAG_CATEGORY_FAILURE: {
            return {
                ...state,
                categoriesLoading: false,
            }
        }

        default: return state;
    }
}