import { FETCH_CONFIGURATION, UPDATE_GROUP_CONFIGURATION_SUCCESS, FETCH_CONFIGURATION_SUCCESS, FETCH_SCHEMAS, FETCH_SCHEMAS_FAILURE, FETCH_SCHEMAS_SUCCESS, FETCH_SCHEMAS_FINAL, FETCH_CONFIGURATION_FAILURE, FETCH_CONFIGURATION_FINAL, UPDATE_TENANT_CONFIGURATION, UPDATE_GROUP_CONFIGURATION, UPDATE_TENANT_CONFIGURATION_SUCCESS, UPDATE_TENANT_CONFIGURATION_FAILURE, UPDATE_GROUP_CONFIGURATION_FAILURE, FETCH_GROUP_CONFIGURATION, FETCH_GROUP_CONFIGURATION_SUCCESS, FETCH_GROUP_CONFIGURATION_FAILURE, FETCH_GROUP_CONFIGURATION_FINAL, DELETE_TENANT_CONFIGURATION, DELETE_GROUP_CONFIGURATION, DELETE_TENANT_CONFIGURATION_SUCCESS, DELETE_TENANT_CONFIGURATION_FAILURE, DELETE_GROUP_CONFIGURATION_SUCCESS, DELETE_GROUP_CONFIGURATION_FAILURE, FETCH_DEVICE_CONFIGURATION, FETCH_DEVICE_CONFIGURATION_SUCCESS, FETCH_DEVICE_CONFIGURATION_FAILURE, FETCH_DEVICE_CONFIGURATION_FINAL, UPDATE_DEVICE_CONFIGURATION, UPDATE_DEVICE_CONFIGURATION_FAILURE, UPDATE_DEVICE_CONFIGURATION_SUCCESS, DELETE_DEVICE_CONFIGURATION, DELETE_DEVICE_CONFIGURATION_SUCCESS, DELETE_DEVICE_CONFIGURATION_FAILURE, FETCH_TENANT_SCHEMAS, FETCH_TENANT_SCHEMAS_SUCCESS, FETCH_TENANT_SCHEMAS_FAILURE, FETCH_TENANT_SCHEMAS_FINAL, UPDATE_TENANT_SCHEMAS, UPDATE_TENANT_SCHEMAS_SUCCESS, UPDATE_TENANT_SCHEMAS_FAILURE, CREATE_SCHEMA, CREATE_SCHEMA_SUCCESS, CREATE_SCHEMA_FAILURE, UPDATE_SCHEMA, UPDATE_SCHEMA_SUCCESS, UPDATE_SCHEMA_FAILURE, DELETE_SCHEMA, DELETE_SCHEMA_SUCCESS, DELETE_SCHEMA_FAILURE } from '../constants/configurationsTypes';

const INIT_STATE = {
    schemas: [],
    createdSchema: {},
    tenantSchemas: [],
    configurations: [],
    fetchedGroup: false,
    fetchedDevice: false,
    schemasLoading: false,
    tenantSchemasLoading: false,
    configurationsLoading: false,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_SCHEMAS: {
            return {
                ...state,
                schemasLoading: true
            }
        }
        case FETCH_SCHEMAS_SUCCESS: {
            return {
                ...state,
                schemas: action.payload.data
            }
        }
        case FETCH_SCHEMAS_FAILURE:
        case FETCH_SCHEMAS_FINAL: {
            return {
                ...state,
                schemasLoading: false
            }
        }

        case CREATE_SCHEMA: {
            return {
                ...state,
                schemasLoading: true
            }
        }
        case CREATE_SCHEMA_SUCCESS: {
            return {
                ...state,
                schemasLoading: false,
                createdSchema: action.payload.data
            }
        }
        case CREATE_SCHEMA_FAILURE: {
            return {
                ...state,
                schemasLoading: false
            }
        }

        case UPDATE_SCHEMA: {
            return {
                ...state,
                schemasLoading: true
            }
        }
        case UPDATE_SCHEMA_SUCCESS:
        case UPDATE_SCHEMA_FAILURE: {
            return {
                ...state,
                schemasLoading: false
            }
        }

        case DELETE_SCHEMA: {
            return {
                ...state,
                schemasLoading: true
            }
        }
        case DELETE_SCHEMA_SUCCESS:
        case DELETE_SCHEMA_FAILURE: {
            return {
                ...state,
                schemasLoading: false
            }
        }


        case UPDATE_TENANT_SCHEMAS:
        case FETCH_TENANT_SCHEMAS: {
            return {
                ...state,
                tenantSchemasLoading: true
            }
        }
        case FETCH_TENANT_SCHEMAS_SUCCESS: {
            return {
                ...state,
                tenantSchemas: action.payload.data
            }
        }
        case UPDATE_TENANT_SCHEMAS_SUCCESS:
        case UPDATE_TENANT_SCHEMAS_FAILURE:
        case FETCH_TENANT_SCHEMAS_FAILURE:
        case FETCH_TENANT_SCHEMAS_FINAL: {
            return {
                ...state,
                tenantSchemasLoading: false
            }
        }


        case FETCH_DEVICE_CONFIGURATION:
        case FETCH_GROUP_CONFIGURATION:
        case FETCH_CONFIGURATION: {
            return {
                ...state,
                configurationsLoading: true
            }
        }
        case FETCH_DEVICE_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                configurations: action.payload.data,
                fetchedDevice: action.payload.fetchedDevice || false
            }
        }
        case FETCH_GROUP_CONFIGURATION_SUCCESS:
        case FETCH_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                configurations: action.payload.data,
                fetchedGroup: action.payload.fetchedGroup || false
            }
        }
        case FETCH_DEVICE_CONFIGURATION_FAILURE:
        case FETCH_DEVICE_CONFIGURATION_FINAL:
        case FETCH_GROUP_CONFIGURATION_FAILURE:
        case FETCH_GROUP_CONFIGURATION_FINAL:
        case FETCH_CONFIGURATION_FAILURE:
        case FETCH_CONFIGURATION_FINAL: {
            return {
                ...state,
                configurationsLoading: false
            }
        }


        case UPDATE_DEVICE_CONFIGURATION:
        case UPDATE_TENANT_CONFIGURATION:
        case UPDATE_GROUP_CONFIGURATION: {
            return {
                ...state,
                configurationsLoading: true
            }
        }
        case UPDATE_DEVICE_CONFIGURATION_SUCCESS:
        case UPDATE_DEVICE_CONFIGURATION_FAILURE:
        case UPDATE_TENANT_CONFIGURATION_SUCCESS:
        case UPDATE_TENANT_CONFIGURATION_FAILURE:
        case UPDATE_GROUP_CONFIGURATION_SUCCESS:
        case UPDATE_GROUP_CONFIGURATION_FAILURE: {
            return {
                ...state,
                configurationsLoading: false
            }
        }

        case DELETE_DEVICE_CONFIGURATION:
        case DELETE_TENANT_CONFIGURATION:
        case DELETE_GROUP_CONFIGURATION: {
            return {
                ...state,
                configurationsLoading: true
            }
        }
        case DELETE_DEVICE_CONFIGURATION_SUCCESS:
        case DELETE_DEVICE_CONFIGURATION_FAILURE:
        case DELETE_TENANT_CONFIGURATION_SUCCESS:
        case DELETE_TENANT_CONFIGURATION_FAILURE:
        case DELETE_GROUP_CONFIGURATION_SUCCESS:
        case DELETE_GROUP_CONFIGURATION_FAILURE: {
            return {
                ...state,
                configurationsLoading: false
            }
        }

        default: return state;
    }
}
