// Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';


const styles = () => ({
    progress: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});


const CenterSpinner = (props) => {
    return (
        <div className={props.classes.progress}>
            <CircularProgress color={props.color || "secondary"} size={props.size || 50} />
        </div>
    )
}

CenterSpinner.propTypes = {
    classes: PropTypes.object,
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
    size: PropTypes.number,
}

export default withStyles(CenterSpinner, styles);
