import { NOTIFY_CLOSE, NOTIFY_OPEN } from '../constants/notificationTypes';

export const notifyOpen = (notification) => {
    return {
        type: NOTIFY_OPEN,
        payload: notification
    }
}

export const notifyClose = () => {
    return {
        type: NOTIFY_CLOSE
    }
}