import general from './general.json';
import dashboard from './dashboard.json';
import table from './table.json';
import tenantManagement from './tenantManagement.json';
import tabs from './tabs.json';
import userManagement from './userManagement.json';
import groupManagement from './groupManagement.json'
import deviceManagement from './deviceManagement.json';
import deviceConfiguration from './deviceConfiguration.json';
import tenantConfiguration from './tenantConfiguration.json';
import tagManagement from './tagManagement.json';
import actionBar from './actionBar.json';
import simpleLocations from './simpleLocations.json';
import simpleLocationItems from './simpleLocationItems.json';
import sales from './sales.json';

export default {
    translation: {
        ...general,
        ...dashboard,
        ...table,
        ...tenantManagement,
        ...tabs,
        ...userManagement,
        ...groupManagement,
        ...deviceManagement,
        ...deviceConfiguration,
        ...tenantConfiguration,
        ...tagManagement,
        ...actionBar,
        ...simpleLocations,
        ...simpleLocationItems,
        ...sales
    }
}