import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import translations from './translations';

/**
 * Resources variable that defines all available languages and their corresponding translations
 */
const resources = translations

export const languages = Object.keys(translations);

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en-GB',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n