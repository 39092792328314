import { FETCH_TENANT, FETCH_TENANTS, FETCH_TENANT_FINAL, FETCH_TENANT_SUCCESS, FETCH_TENANTS_SUCCESS, FETCH_TENANTS_FINAL, CREATE_TENANT, CREATE_TENANT_SUCCESS, CREATE_TENANT_FAILURE, UPDATE_TENANT, UPDATE_TENANT_SUCCESS, UPDATE_TENANT_FAILURE, DELETE_TENANT, DELETE_TENANT_SUCCESS, DELETE_TENANT_FAILURE, FETCH_TENANTS_WITH_SALES_DATA, FETCH_TENANTS_WITH_SALES_DATA_SUCCESS, FETCH_TENANTS_WITH_SALES_DATA_FAILURE, FETCH_TENANTS_WITH_SALES_DATA_FINAL } from '../constants/tenantsTypes';

const INIT_STATE = {
    tenant: {},
    tenants: [],
    tenantsWithSalesData: [],
    tenantsWithSalesDataLoading: false,
    tenantLoading: false,
    tenantsLoading: false,
    totalCount: 0,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TENANT: {
            return {
                ...state,
                tenantLoading: true
            }
        }
        case FETCH_TENANT_SUCCESS: {
            return {
                ...state,
                tenant: action.payload.data,
            }
        }
        case FETCH_TENANT_FINAL: {
            return {
                ...state,
                tenantLoading: false
            }
        }

        case FETCH_TENANTS: {
            return {
                ...state,
                tenantsLoading: true
            }
        }
        case FETCH_TENANTS_SUCCESS: {
            return {
                ...state,
                tenants: action.payload.data,
                totalCount: action.payload.data.length
            }
        }
        case FETCH_TENANTS_FINAL: {
            return {
                ...state,
                tenantsLoading: false
            }
        }
        case FETCH_TENANTS_WITH_SALES_DATA: {
            return {
                ...state,
                tenantsWithSalesDataLoading: true
            }
        }
        case FETCH_TENANTS_WITH_SALES_DATA_SUCCESS: {
            return {
                ...state,
                tenantsWithSalesData: action.payload.data
            }
        }
        case FETCH_TENANTS_WITH_SALES_DATA_FAILURE:
        case FETCH_TENANTS_WITH_SALES_DATA_FINAL: {
            return {
                ...state,
                tenantsWithSalesDataLoading: false
            }
        }


        case CREATE_TENANT: {
            return {
                ...state,
                tenantsLoading: true
            }
        }
        case CREATE_TENANT_SUCCESS:
        case CREATE_TENANT_FAILURE: {
            return {
                ...state,
                tenantsLoading: false
            }
        }

        case UPDATE_TENANT: {
            return {
                ...state,
                tenantsLoading: true
            }
        }
        case UPDATE_TENANT_SUCCESS:
        case UPDATE_TENANT_FAILURE: {
            return {
                ...state,
                tenantsLoading: false
            }
        }

        case DELETE_TENANT: {
            return {
                ...state,
                tenantsLoading: true
            }
        }
        case DELETE_TENANT_SUCCESS:
        case DELETE_TENANT_FAILURE: {
            return {
                ...state,
                tenantsLoading: false
            }
        }

        default: return state;
    }
}