// Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { ClickAwayListener, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, SwipeableDrawer, Typography } from '@mui/material';
import { Business, Clear, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


const styles = (theme) => ({

    root: {
        width: '100%',
    },
    list: {
        width: '35rem',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedParent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    searchInput: {
        marginLeft: '15px',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});


const Sidebar = (props) => {

    const { t } = useTranslation();
    const [searchOpen, setSearchOpen] = React.useState(false);

    const handleSearchClick = (value) => {
        (value !== null && value !== undefined) ? setSearchOpen(value) : setSearchOpen(!searchOpen);
    }

    return (
        <SwipeableDrawer className={props.classes.root} open={props.isOpen} anchor="left" onOpen={() => props.open(true)} onClose={() => props.open(false)}>
            <List className={props.classes.list}>
                <ListItem className={props.classes.searchContainer}>
                    <Typography variant="h6">{t('tabsTenants')}</Typography>
                    <div className={props.classes.searchInput}>
                        <ClickAwayListener onClickAway={() => handleSearchClick(false)}>
                            {searchOpen ? (
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="outlined-search-input" color="secondary">{t('search')}</InputLabel>
                                    <OutlinedInput 
                                        id="outlined-search-input"
                                        color="secondary" 
                                        onChange={props.onSearchChange} 
                                        value={props.searchValue} 
                                        variant="outlined"  
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={props.onClear} size="large">
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        }  
                                        labelWidth={50} 
                                    />
                                </FormControl>
                            ) : (
                                <span onClick={handleSearchClick} style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton size="large">
                                        <Search />
                                    </IconButton>
                                    <Typography>{props.searchValue}</Typography>
                                </span>
                            )}
                        </ClickAwayListener>
                    </div>
                </ListItem>
                <div data-cy="sidebarTenantList">
                    {props.data && props.data.map((item, index) => (
                        <span key={index}>
                            <span className={props.classes.nestedParent}>
                                <ListItemButton data-cy={item.name} onClick={() => props.onClick(item)} className={props.classes.nested} selected={props.tenantId && item.id === props.tenantId ? true : false}>
                                    <ListItemText primary={item.name} primaryTypographyProps={{ variant: "h5" }} />
                                    <ListItemIcon>
                                        {item.logoUrl !== null ? (
                                            <img src={item.logoUrl} alt={item.name} style={{ maxWidth: '200px', maxHeight: '50px', marginRight: '25px' }} />
                                        ) : (
                                            <Business />
                                        )}
                                    </ListItemIcon>
                                </ListItemButton>
                            </span>
                            <Divider />
                        </span>
                    ))}
                </div>
            </List>
        </SwipeableDrawer>
    );
}

Sidebar.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    tenantId: PropTypes.string,
}

export default withStyles(Sidebar, styles);
