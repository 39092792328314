import { FETCH_DEVICE_HEARTBEAT, FETCH_DEVICE_HEARTBEAT_FAILURE, FETCH_DEVICE_HEARTBEAT_SUCCESS, FETCH_DEVICE_HEARTBEAT_FINAL, CREATE_GROUP_DEVICE, CREATE_TENANT_DEVICE, CREATE_TENANT_DEVICE_FAILURE, CREATE_TENANT_DEVICE_SUCCESS, DELETE_DEVICE, DELETE_DEVICE_FAILURE, DELETE_DEVICE_SUCCESS, FETCH_DEVICE_STATUS, FETCH_DEVICE_STATUS_FAILURE, FETCH_DEVICE_STATUS_FINAL, FETCH_DEVICE_STATUS_SUCCESS, FETCH_GROUP_DEVICES, FETCH_GROUP_DEVICES_FAILURE, FETCH_GROUP_DEVICES_FINAL, FETCH_GROUP_DEVICES_SUCCESS, FETCH_TENANT_DEVICES, FETCH_TENANT_DEVICES_FAILURE, FETCH_TENANT_DEVICES_FINAL, FETCH_TENANT_DEVICES_SUCCESS, REGISTER_DEVICE, REGISTER_DEVICE_FAILURE, REGISTER_DEVICE_SUCCESS, RESEND_ACTIVATION, RESEND_ACTIVATION_FAILURE, RESEND_ACTIVATION_SUCCESS, UPDATE_DEVICE, UPDATE_DEVICE_FAILURE, UPDATE_DEVICE_SUCCESS, VALIDATE_DEVICE, VALIDATE_DEVICE_FAILURE, VALIDATE_DEVICE_SUCCESS } from '../constants/devicesTypes';

const INIT_STATE = {
    tenantDevices: [],
    groupDevices: [],
    statuses: [],
    heartbeats: [],
    device: {},
    loadingDevices: false,
    loadingDevice: false,
    loadingStatuses: false,
    loadingHeartbeats: false,
    resendingActivation: false,
    totalCount: 0,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TENANT_DEVICES:
        case FETCH_GROUP_DEVICES:
        case CREATE_GROUP_DEVICE:
        case CREATE_TENANT_DEVICE:
        case UPDATE_DEVICE:
        case DELETE_DEVICE: {
            return {
                ...state,
                loadingDevices: true
            }
        }

        case FETCH_GROUP_DEVICES_SUCCESS: {
            return {
                ...state,
                groupDevices: action.payload.data,
                totalCount: action.payload.data.length
            }
        }

        case FETCH_TENANT_DEVICES_SUCCESS: {
            return {
                ...state,
                tenantDevices: action.payload.data,
                totalCount: action.payload.data.length
            }
        }

        case FETCH_TENANT_DEVICES_FAILURE:
        case FETCH_TENANT_DEVICES_FINAL:
        case CREATE_TENANT_DEVICE_SUCCESS:
        case CREATE_TENANT_DEVICE_FAILURE:
        case FETCH_GROUP_DEVICES_FAILURE:
        case FETCH_GROUP_DEVICES_FINAL:
        case UPDATE_DEVICE_FAILURE:
        case UPDATE_DEVICE_SUCCESS:
        case DELETE_DEVICE_SUCCESS:
        case DELETE_DEVICE_FAILURE: {
            return {
                ...state,
                loadingDevices: false
            }
        }
        

        case VALIDATE_DEVICE: return state;
        case VALIDATE_DEVICE_SUCCESS:
        case VALIDATE_DEVICE_FAILURE: return state;

        case REGISTER_DEVICE: return state;
        case REGISTER_DEVICE_SUCCESS:
        case REGISTER_DEVICE_FAILURE: return state;

        case FETCH_DEVICE_STATUS: {
            return {
                ...state,
                loadingStatuses: true
            }
        }

        case FETCH_DEVICE_STATUS_SUCCESS: {
            return {
                ...state,
                statuses: action.payload.data,
                totalCount: action.payload.data.length
            }
        }

        case FETCH_DEVICE_STATUS_FAILURE:
        case FETCH_DEVICE_STATUS_FINAL: {
            return {
                ...state,
                loadingStatuses: false
            }
        }

        case FETCH_DEVICE_HEARTBEAT: {
            return {
                ...state,
                loadingHeartbeats: true
            }
        }

        case FETCH_DEVICE_HEARTBEAT_SUCCESS: {
            return {
                ...state,
                heartbeats: action.payload.data
            }
        }

        case FETCH_DEVICE_HEARTBEAT_FAILURE:
        case FETCH_DEVICE_HEARTBEAT_FINAL: {
            return {
                ...state,
                loadingHeartbeats: false
            }
        }

        case RESEND_ACTIVATION: {
            return {
                ...state,
                resendingActivation: true
            }
        }

        case RESEND_ACTIVATION_SUCCESS:
        case RESEND_ACTIVATION_FAILURE: {
            return {
                ...state,
                resendingActivation: false
            }
        }

        default: return state;
    }
}