import { CREATE_USER, CREATE_USER_FAILURE, CREATE_USER_SUCCESS, DELETE_USER, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, FETCH_USERS, FETCH_USERS_FINAL, FETCH_USERS_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from '../constants/usersTypes';

const INIT_STATE = {
    users: [],
    loadingUsers: false,
    pagination: null,
    totalCount: 0
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload.data,
                totalCount: action.payload.data.length
            }
        }
        case FETCH_USERS_FINAL: {
            return {
                ...state,
                loadingUsers: false
            }
        }

        case FETCH_USERS:
        case CREATE_USER:
        case DELETE_USER:
        case UPDATE_USER: {
            return {
                ...state,
                loadingUsers: true
            }
        }

        case CREATE_USER_SUCCESS:
        case DELETE_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
        case CREATE_USER_FAILURE:
        case UPDATE_USER_FAILURE:
        case DELETE_USER_FAILURE: {
            return {
                ...state,
                loadingUsers: false
            }
        }

        default: return state;
    }
}