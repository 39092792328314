import { all } from 'redux-saga/effects';

import tenantSagas from './tenants';
import userSagas from './users';
import groupSagas from './groups';
import deviceSagas from './devices';
import configurationSagas from './configuration';
import tagCategorySagas from './tagCategories';
import tagLocationSagas from './tagLocations';
import tagContentSagas from './tagContents';
import tagSagas from './tags';

export default function* rootSaga() {
    yield all([
        tenantSagas(),
        userSagas(),
        groupSagas(),
        deviceSagas(),
        configurationSagas(),
        tagCategorySagas(),
        tagLocationSagas(),
        tagContentSagas(),
        tagSagas(),
    ]);
}