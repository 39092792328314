import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_TAG, DELETE_TAG, FETCH_TAG, FETCH_TAGS, UPDATE_TAG } from '../constants/tagTypes';
import { fetchTags as fetchTagsAction, fetchTagsFailure, fetchTagsFinal, fetchTagsSuccess, fetchTagSuccess, fetchTagFailure, fetchTagFinal, notifyOpen } from '../actions';

function fetchTagsRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/anchors`);
}

function* fetchTags({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagsRequest, payload.tenantId);

        yield put(fetchTagsSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagsFailure());
    } finally {
        yield put(fetchTagsFinal());
    }
}

export function* watchFetchTags() {
    yield takeLatest(FETCH_TAGS, fetchTags);
}

function fetchTagRequest(tagId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/taganchors/${tagId}`);
}

function* fetchTag({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagRequest, payload.tagId);

        yield put(fetchTagSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagFailure());
    } finally {
        yield put(fetchTagFinal());
    }
}

export function* watchFetchTag() {
    yield takeLatest(FETCH_TAG, fetchTag);
}


function createTagRequest(tenantId, tag) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/anchors`, tag);
}

function* createTag({ payload }) {
    try {
        yield call(createTagRequest, payload.tenantId, payload.tag);
        yield put(notifyOpen({
            text: 'Successfully created tag',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to create tag',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagsAction(payload.tenantId));
    }
}

export function* watchCreateTag() {
    yield takeLatest(CREATE_TAG, createTag);
}

function updateTagRequest(tag) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/taganchors/${tag.id}`, {
        title: tag.title,
        key: tag.key
    });
}

function* updateTag({ payload }) {
    try {
        yield call(updateTagRequest, payload.tag);
        yield put(notifyOpen({
            text: 'Successfully updated tag',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to update tag',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagsAction(payload.tenantId));
    }
}

export function* watchUpdateTag() {
    yield takeLatest(UPDATE_TAG, updateTag);
}

function deleteTagRequest(tagId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/taganchors/${tagId}`);
}

function* deleteTag({ payload }) {
    try {
        yield call(deleteTagRequest, payload.tagId);
        yield put(notifyOpen({
            text: 'Successfully deleted tag',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to delete tag',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagsAction(payload.tenantId));
    }
}

export function* watchDeleteTag() {
    yield takeLatest(DELETE_TAG, deleteTag);
}


export default function* rootSaga() {
    yield all([
        fork(watchFetchTags),
        fork(watchFetchTag),
        fork(watchCreateTag),
        fork(watchUpdateTag),
        fork(watchDeleteTag)
    ]);
}
