import { FETCH_TAG_LOCATION, FETCH_TAG_LOCATIONS, FETCH_TAG_LOCATIONS_FAILURE, FETCH_TAG_LOCATIONS_FINAL, FETCH_TAG_LOCATIONS_SUCCESS, FETCH_TAG_LOCATION_SUCCESS, FETCH_TAG_LOCATION_FAILURE, DELETE_TAG_LOCATION, DELETE_TAG_LOCATION_FAILURE, DELETE_TAG_LOCATION_SUCCESS, FETCH_TAG_LOCATION_FINAL, UPDATE_TAG_LOCATION, UPDATE_TAG_LOCATION_FAILURE, UPDATE_TAG_LOCATION_SUCCESS } from '../constants/tagLocationsTypes';

const INIT_STATE = {
    locations: [],
    locationsLoading: false,
    location: {},
    locationLoading: false,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case FETCH_TAG_LOCATIONS: {
            return {
                ...state,
                locationsLoading: true,
            }
        }
        case FETCH_TAG_LOCATIONS_SUCCESS: {
            return {
                ...state,
                locations: action.payload.data
            }
        }
        case FETCH_TAG_LOCATIONS_FAILURE:
        case FETCH_TAG_LOCATIONS_FINAL: {
            return {
                ...state,
                locationsLoading: false,
            }
        }

        case FETCH_TAG_LOCATION: {
            return {
                ...state,
                locationLoading: true,
            }
        }
        case FETCH_TAG_LOCATION_SUCCESS: {
            return {
                ...state,
                location: action.payload
            }
        }
        case FETCH_TAG_LOCATION_FAILURE:
        case FETCH_TAG_LOCATION_FINAL: {
            return {
                ...state,
                locationLoading: false,
            }
        }

        case UPDATE_TAG_LOCATION: {
            return {
                ...state,
                locationsLoading: true,
            }
        }
        case UPDATE_TAG_LOCATION_SUCCESS:
        case UPDATE_TAG_LOCATION_FAILURE: {
            return {
                ...state,
                locationsLoading: false,
            }
        }

        case DELETE_TAG_LOCATION: {
            return {
                ...state,
                locationsLoading: true,
            }
        }
        case DELETE_TAG_LOCATION_SUCCESS:
        case DELETE_TAG_LOCATION_FAILURE: {
            return {
                ...state,
                locationsLoading: false,
            }
        }

        default: return state;
    }
}