import React from "react";
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';

import { withStyles } from 'tss-react/mui';

import CenterSpinner from "./CenterSpinner";
import { mainTheme } from "../utils/customMUI";

const styles = () => ({
  main: {
    height: '100vh'
  }
});

const AuthLoader = (props) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <div className={props.classes.main}>
        <CenterSpinner color="primary" />
      </div>
    </ThemeProvider>
  );
}

AuthLoader.propTypes = {
  classes: PropTypes.object,
}


export default withStyles(AuthLoader, styles);
