/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_TENANT, DELETE_TENANT, FETCH_TENANT, FETCH_TENANTS, FETCH_TENANTS_WITH_SALES_DATA, UPDATE_TENANT } from '../constants/tenantsTypes';
import { 
    updateTenantSchemas, 
    fetchTenant as fetchTenantAction, 
    fetchTenants as fetchTenantsAction, 
    fetchTenantFailure, 
    fetchTenantFinal, 
    fetchTenantsFailure, 
    fetchTenantsFinal, 
    fetchTenantsSuccess, 
    fetchTenantSuccess, 
    notifyOpen, 
    fetchTenantsWithSalesDataSuccess, 
    fetchTenantsWithSalesDataFailure, 
    fetchTenantsWithSalesDataFinal, 
} from '../actions';
import { fetchUsersRequest } from './users';
import { fetchTenantDevicesRequest } from './devices';

function fetchTenantRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}`);
}

function* fetchTenant({ payload }) {
    try {
        const { data } = yield call(fetchTenantRequest, payload.tenantId);

        yield put(fetchTenantSuccess({
            data
        }));
    } catch (err) {
        yield put(fetchTenantFailure());
    } finally {
        yield put(fetchTenantFinal());
    }
}

export function* watchFetchTenant() {
    yield takeLatest(FETCH_TENANT, fetchTenant);
}


function fetchTenantsRequest() {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants`);
}

function* fetchTenants({ payload }) {
    try {
        let combinedData = [];
        const tenants = yield call(fetchTenantsRequest);

        if (payload.withUsers) {
            for (const tenant of tenants.data) {
                const users = yield call(fetchUsersRequest, tenant.id)
                const owner = users.data.length > 0 && users.data.find((user) => user.roles.find((role) => role === 'tenant_owner'));
                combinedData.push({ ...tenant, ownerEmail: owner && owner.email || 'None' });
            }
        } else {
            combinedData = tenants.data;
        }

        yield put(fetchTenantsSuccess({
            data: combinedData
        }));
    } catch (err) {
        yield put(fetchTenantsFailure());
    } finally {
        yield put(fetchTenantsFinal());
    }
}

export function* watchFetchTenants() {
    yield takeLatest(FETCH_TENANTS, fetchTenants);
}

function* fetchTenantsWithSalesData() {
    try {
        let combinedData = [];
        const tenants = yield call(fetchTenantsRequest);

        if (tenants.data.length > 0) {
            for (const tenant of tenants.data) {
                const devices = yield call(fetchTenantDevicesRequest, tenant.id);
                combinedData.push({ ...tenant, totalDevices: devices.data.length });
            }
        }

        // Sorts the array of tenants with sales data so that the tenants with too many devices are listed at the top
        combinedData.sort((first) => first.totalDevices > first.maxDevices ? -1 : 1 );

        yield put(fetchTenantsWithSalesDataSuccess({
            data: combinedData
        }));
    } catch (err) {
        yield put(fetchTenantsWithSalesDataFailure());
    } finally {
        yield put(fetchTenantsWithSalesDataFinal());
    }
}

export function* watchFetchTenantsWithSalesData() {
    yield takeLatest(FETCH_TENANTS_WITH_SALES_DATA, fetchTenantsWithSalesData);
}


function createTenantRequest(tenant) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants`, tenant, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function* createTenant({ payload }) {
    try {
        const { data } = yield call(createTenantRequest, payload.tenant);
        if (payload.modules.length > 0) {
            yield put(updateTenantSchemas(data.id, payload.modules));
        }

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created Tenant'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create Tenant'
        }));
    } finally {
        yield put(fetchTenantsAction());
    }
}

export function* watchCreateTenant() {
    yield takeLatest(CREATE_TENANT, createTenant);
}


function updateTenantRequest(tenantId, data) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function* updateTenant({ payload }) {
    try {
        yield call(updateTenantRequest, payload.id, payload.data);

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully updated Tenant'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to update Tenant'
        }));
    } finally {
        yield put(fetchTenantsAction());
        yield put(fetchTenantAction(payload.id));
    }
}

export function* watchUpdateTenant() {
    yield takeLatest(UPDATE_TENANT, updateTenant);
}


function deleteTenantRequest(tenantId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}`);
}

function* deleteTenant({ payload }) {
    try {
        yield call(deleteTenantRequest, payload.tenantId);

        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully deleted Tenant'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to delete Tenant'
        }));
    } finally {
        yield put(fetchTenantsAction());
    }
}

export function* watchDeleteTenant() {
    yield takeLatest(DELETE_TENANT, deleteTenant);
}


export default function* rootSaga() {
    yield all([
        fork(watchFetchTenant),
        fork(watchFetchTenants),
        fork(watchCreateTenant),
        fork(watchUpdateTenant),
        fork(watchDeleteTenant),
        fork(watchFetchTenantsWithSalesData)
    ]);
}
