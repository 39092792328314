import { FETCH_TAG_LOCATION, FETCH_TAG_LOCATIONS, FETCH_TAG_LOCATIONS_FAILURE, FETCH_TAG_LOCATIONS_FINAL, FETCH_TAG_LOCATIONS_SUCCESS, FETCH_TAG_LOCATION_FAILURE, FETCH_TAG_LOCATION_FINAL, FETCH_TAG_LOCATION_SUCCESS, DELETE_TAG_LOCATION, DELETE_TAG_LOCATION_FAILURE, DELETE_TAG_LOCATION_SUCCESS, UPDATE_TAG_LOCATION, UPDATE_TAG_LOCATION_FAILURE, UPDATE_TAG_LOCATION_SUCCESS, CREATE_TAG_LOCATION, CREATE_TAG_LOCATION_SUCCESS, CREATE_TAG_LOCATION_FAILURE } from '../constants/tagLocationsTypes';

export const fetchTagLocations = (tenantId) => {
    return {
        type: FETCH_TAG_LOCATIONS,
        payload: {
            tenantId
        }
    }
}

export const fetchTagLocationsSuccess = (tagLocations) => {
    return {
        type: FETCH_TAG_LOCATIONS_SUCCESS,
        payload: tagLocations
    }
}

export const fetchTagLocationsFailure = () => {
    return {
        type: FETCH_TAG_LOCATIONS_FAILURE
    }
}

export const fetchTagLocationsFinal = () => {
    return {
        type: FETCH_TAG_LOCATIONS_FINAL
    }
}

export const fetchTagLocation = (tagLocationId) => {
    return {
        type: FETCH_TAG_LOCATION,
        payload: {
            tagLocationId
        }
    }
}

export const fetchTagLocationSuccess = (tagLocation) => {
    return {
        type: FETCH_TAG_LOCATION_SUCCESS,
        payload: tagLocation
    }
}

export const fetchTagLocationFailure = () => {
    return {
        type: FETCH_TAG_LOCATION_FAILURE
    }
}

export const fetchTagLocationFinal = () => {
    return {
        type: FETCH_TAG_LOCATION_FINAL
    }
}

export const createTagLocation = (tenantId, tagLocation) => {
    return {
        type: CREATE_TAG_LOCATION,
        payload: {
            tenantId,
            tagLocation
        }
    }
}

export const createTagLocationSuccess = () => {
    return {
        type: CREATE_TAG_LOCATION_SUCCESS,
    }
}

export const createTagLocationFailure = () => {
    return {
        type: CREATE_TAG_LOCATION_FAILURE
    }
}

export const updateTagLocation = (tenantId, tagLocation) => {
    return {
        type: UPDATE_TAG_LOCATION,
        payload: {
            tenantId, tagLocation
        }
    }
}

export const updateTagLocationSuccess = () => {
    return {
        type: UPDATE_TAG_LOCATION_SUCCESS
    }
}

export const updateTagLocationFailure = () => {
    return {
        type: UPDATE_TAG_LOCATION_FAILURE
    }
}

export const deleteTagLocation = (tenantId, tagLocationId) => {
    return {
        type: DELETE_TAG_LOCATION,
        payload: {
            tenantId, tagLocationId
        }
    }
}

export const deleteTagLocationSuccess = () => {
    return {
        type: DELETE_TAG_LOCATION_SUCCESS
    }
}

export const deleteTagLocationFailure = () => {
    return {
        type: DELETE_TAG_LOCATION_FAILURE
    }
}


