export const FETCH_TAGS = 'fetch_tags';
export const FETCH_TAGS_SUCCESS = 'fetch_tags_success';
export const FETCH_TAGS_FAILURE = 'fetch_tags_failure';
export const FETCH_TAGS_FINAL = 'fetch_tags_final';

export const FETCH_TAG = 'fetch_tag';
export const FETCH_TAG_SUCCESS = 'fetch_tag_success';
export const FETCH_TAG_FAILURE = 'fetch_tag_failure';
export const FETCH_TAG_FINAL = 'fetch_tag_final';

export const CREATE_TAG = 'create_tag';
export const CREATE_TAG_SUCCESS = 'create_tag_success';
export const CREATE_TAG_FAILURE = 'create_tag_failure';

export const UPDATE_TAG = 'update_tag';
export const UPDATE_TAG_SUCCESS = 'update_tag_success';
export const UPDATE_TAG_FAILURE = 'update_tag_failure';

export const DELETE_TAG = 'delete_tag';
export const DELETE_TAG_SUCCESS = 'delete_tag_success';
export const DELETE_TAG_FAILURE = 'delete_tag_failure';
