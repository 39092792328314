import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { CREATE_GROUP_DEVICE, CREATE_TENANT_DEVICE, DELETE_DEVICE, FETCH_DEVICE_HEARTBEAT, FETCH_DEVICE_STATUS, FETCH_GROUP_DEVICES, FETCH_TENANT_DEVICES, REGISTER_DEVICE, RESEND_ACTIVATION, UPDATE_DEVICE, VALIDATE_DEVICE } from '../constants/devicesTypes';
import { fetchDeviceHeartbeatFailure, fetchDeviceHeartbeatFinal, fetchDeviceHeartbeatSuccess, fetchDeviceStatusFailure, fetchDeviceStatusFinal, fetchDeviceStatusSuccess, fetchGroupDevices as fetchGroupDevicesAction, fetchGroupDevicesFailure, fetchGroupDevicesFinal, fetchGroupDevicesSuccess, fetchTenantDevices as fetchTenantDevicesAction, fetchTenantDevicesFailure, fetchTenantDevicesFinal, fetchTenantDevicesSuccess, notifyOpen, resendActivationFailure, resendActivationSuccess } from '../actions';

export function fetchTenantDevicesRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/devices`);
}

function* fetchTenantDevices({ payload }) {
    try {
        const { data, headers } = yield call(fetchTenantDevicesRequest, payload.tenantId);

        yield put(fetchTenantDevicesSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTenantDevicesFailure());
    } finally {
        yield put(fetchTenantDevicesFinal());
    }
}

export function* watchFetchTenantDevices() {
    yield takeLatest(FETCH_TENANT_DEVICES, fetchTenantDevices);
}

function createTenantDeviceRequest(tenantId, type, ownerEmail) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/devices`, {
        type, ownerEmail
    });
}

function* createTenantDevice({ payload }) {
    try {
        yield call(createTenantDeviceRequest, payload.tenantId, payload.type, payload.ownerEmail);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created tenant device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create tenant device'
        }));
    } finally {
        yield put(fetchTenantDevicesAction(payload.tenantId));
    }
}

export function* watchcreateTenantDevice() {
    yield takeLatest(CREATE_TENANT_DEVICE, createTenantDevice);
}

function fetchGroupDevicesRequest(groupId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/groups/${groupId}/devices`);
}

function* fetchGroupDevices({ payload }) {
    try {
        const { data, headers } = yield call(fetchGroupDevicesRequest, payload.groupId);

        yield put(fetchGroupDevicesSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchGroupDevicesFailure());
    } finally {
        yield put(fetchGroupDevicesFinal());
    }
}

export function* watchfetchGroupDevices() {
    yield takeLatest(FETCH_GROUP_DEVICES, fetchGroupDevices);
}

function createGroupDeviceRequest(groupId, type, ownerEmail) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/groups/${groupId}/devices`, {
        type, ownerEmail
    });
}

function* createGroupDevice({ payload }) {
    try {
        yield call(createGroupDeviceRequest, payload.groupId, payload.type, payload.ownerEmail);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully created group device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to create group device'
        }));
    } finally {
        yield put(fetchGroupDevicesAction(payload.groupId));
    }
}

export function* watchcreateGroupDevice() {
    yield takeLatest(CREATE_GROUP_DEVICE, createGroupDevice);
}

function updateDeviceRequest(deviceId, ownerEmail, groupId, type) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}`, {
        ownerEmail,
        groupId,
        type
    });
}

function* updateDevice({ payload }) {
    try {
        yield call(updateDeviceRequest, payload.deviceId, payload.ownerEmail, payload.groupId, payload.deviceType);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully updated device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to update device'
        }));
    } finally {
        payload.fetchType === fetchTypes.TENANT ? 
            yield put(fetchTenantDevicesAction(payload.tenantId)) 
            : 
            yield put(fetchGroupDevicesAction(payload.groupId));
    }
}

export function* watchupdateDevice() {
    yield takeLatest(UPDATE_DEVICE, updateDevice);
}

function deleteDeviceRequest(deviceId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}`);
}

function* deleteDevice({ payload }) {
    try {
        yield call(deleteDeviceRequest, payload.deviceId);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully deleted device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to delete device'
        }));
    } finally {
        payload.fetchType === fetchTypes.TENANT ? 
            yield put(fetchTenantDevicesAction(payload.tenantId)) 
            : 
            yield put(fetchGroupDevicesAction(payload.groupId));
    }
}

export function* watchdeleteDevice() {
    yield takeLatest(DELETE_DEVICE, deleteDevice);
}

function registerDeviceRequest(identifier, registerCode) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/devices/register`, {
        identifier, registerCode
    });
}

function* registerDevice({ payload }) {
    try {
        yield call(registerDeviceRequest, payload.identifier, payload.registerCode);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully registered device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to register device'
        }));
    } finally {
        payload.fetchType === fetchTypes.TENANT ? 
            yield put(fetchTenantDevicesAction(payload.tenantId)) 
            : 
            yield put(fetchGroupDevicesAction(payload.groupId));
    }
}

export function* watchregisterDevice() {
    yield takeLatest(REGISTER_DEVICE, registerDevice);
}

function validateDeviceRequest(identifier) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/devices/validate`, { identifier });
}

function* validateDevice({ payload }) {
    try {
        yield call(validateDeviceRequest, payload.identifier);
        yield put(notifyOpen({
            type: 'success',
            text: 'Successfully validated device'
        }));
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to validate device'
        }));
    } finally {
        payload.fetchType === fetchTypes.TENANT ? 
            yield put(fetchTenantDevicesAction(payload.tenantId)) 
            : 
            yield put(fetchGroupDevicesAction(payload.groupId));
    }
}

export function* watchvalidateDevice() {
    yield takeLatest(VALIDATE_DEVICE, validateDevice);
}


function fetchDeviceStatusRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/devices?monitoring=fault`);
}

function* fetchDeviceStatus({ payload }) {
    try {
        const { data } = yield call(fetchDeviceStatusRequest, payload.tenantId);

        yield put(fetchDeviceStatusSuccess({
            data
        }));
    } catch (error) {
        yield put(fetchDeviceStatusFailure());
    } finally {
        yield put(fetchDeviceStatusFinal());
    }
}

export function* watchFetchDeviceStatus() {
    yield takeLatest(FETCH_DEVICE_STATUS, fetchDeviceStatus);
}


function fetchDeviceHeartbeatRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/tenants/${tenantId}/devices?monitoring=active`);
}

function* fetchDeviceHeartbeat({ payload }) {
    try {
        const { data } = yield call(fetchDeviceHeartbeatRequest, payload.tenantId);

        yield put(fetchDeviceHeartbeatSuccess({
            data
        }));
    } catch (error) {
        yield put(fetchDeviceHeartbeatFailure());
    } finally {
        yield put(fetchDeviceHeartbeatFinal());
    }
}

export function* watchFetchDeviceHeartbeat() {
    yield takeLatest(FETCH_DEVICE_HEARTBEAT, fetchDeviceHeartbeat);
}


function resendActivationRequest(deviceId) {
    /**
     * Empty object required to be sent with request for it to not return a http response code 415
     */
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/devices/${deviceId}?resendEmail=true`, {});
}

function* resendActivation({ payload }) {
    try {
        yield call(resendActivationRequest, payload.deviceId);
        yield put(notifyOpen({
            type: 'success',
            text: 'Resent activation e-mail'
        }));
        yield put(resendActivationSuccess());
    } catch (error) {
        yield put(notifyOpen({
            type: 'error',
            text: error.response.data.error || 'Failed to resend activation e-mail'
        }));
        yield put(resendActivationFailure());
    }
}

export function* watchResendActivation() {
    yield takeLatest(RESEND_ACTIVATION, resendActivation);
}



export default function* rootSaga() {
    yield all([
        fork(watchFetchTenantDevices),
        fork(watchcreateTenantDevice),
        fork(watchfetchGroupDevices),
        fork(watchcreateGroupDevice),
        fork(watchupdateDevice),
        fork(watchdeleteDevice),
        fork(watchregisterDevice),
        fork(watchvalidateDevice),
        fork(watchFetchDeviceStatus),
        fork(watchFetchDeviceHeartbeat),
        fork(watchResendActivation),
    ])
}

export const fetchTypes = {
    TENANT: 'tenant',
    GROUP: 'group'
}
