export const FETCH_GROUPS = 'fetch_groups';
export const FETCH_GROUPS_SUCCESS = 'fetch_groups_success';
export const FETCH_GROUPS_FAILURE = 'fetch_groups_failure';
export const FETCH_GROUPS_FINAL = 'fetch_groups_final';

export const CREATE_GROUP = 'create_group';
export const CREATE_GROUP_SUCCESS = 'create_group_success';
export const CREATE_GROUP_FAILURE = 'create_group_failure';

export const FETCH_GROUP = 'fetch_group';
export const FETCH_GROUP_SUCCESS = 'fetch_group_success';
export const FETCH_GROUP_FAILURE = 'fetch_group_failure';
export const FETCH_GROUP_FINAL = 'fetch_group_final';

export const UPDATE_GROUP = 'update_group';
export const UPDATE_GROUP_SUCCESS = 'update_group_success';
export const UPDATE_GROUP_FAILURE = 'update_group_failure';

export const DELETE_GROUP = 'delete_group';
export const DELETE_GROUP_SUCCESS = 'delete_group_success';
export const DELETE_GROUP_FAILURE = 'delete_group_failure';