// Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { useTranslation } from 'react-i18next';

// Project imports
import CenterSpinner from '../../components/CenterSpinner';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import TabRouter from '../../components/TabRouter';
import { mainTheme } from '../../utils/customMUI';
import { fetchTenants, fetchTenant } from '../../actions';
import useAuth from '../../utils/useAuth';

const Sales = React.lazy(() => import('../Management/Sales'));
const Users = React.lazy(() => import('../Management/Users'));
const Groups = React.lazy(() => import('../Management/Groups'));
const Tenants = React.lazy(() => import('../Management/Tenants'));
const Devices = React.lazy(() => import('../Management/Devices'));
const DeviceMonitoring = React.lazy(() => import('../../components/Monitoring'));
const Configuration = React.lazy(() => import('../Configuration/Brand'));
const DeviceConfiguration = React.lazy(() => import('../Configuration/Device'));
const Dashboard = React.lazy(() => import('../Dashboard'));
const Tags = React.lazy(() => import('../Content/Tags'));
const SimpleLocations = React.lazy(() => import('../Content/SimpleLocations'));
const NotFound = React.lazy(() => import('../../components/NotFound'));

const styles = () => ({

    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fafafa',
        flexGrow: 1,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flexGrow: 1,
    },
});

const Main = (props) => {
    const auth = useAuth();
    const { t } = useTranslation(); 
    const [selectedTenant, setSelectedTenant] = React.useState(auth.user.group_membership[0].id);
    const [createdMuiTheme, setCreatedMuiTheme] = React.useState(mainTheme);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [tenantSearchText, setTenantSearchText] = React.useState('');
    let location = useLocation();
    let navigate = useNavigate();

    const handleAuthorization = (content) => {
        const authCheckResult = isAuthorized();
        if (authCheckResult) {
            return content
        } else {
            return authCheckResult;
        }
    }

    const isAuthorized = () => {
        const userRoles = auth.user.role;

        if (userRoles.includes('super_admin')) {
            return true;
        } else if (userRoles.includes('tenant_owner') || userRoles.includes('tenant_admin')) {
            return false;
        }
        return false;
    }

    const handleRouteAuthorization = () => {
        const userRoles = auth.user.role;
        if (!userRoles.includes('super_admin') && !userRoles.includes('tenant_owner') && !userRoles.includes('tenant_admin')) {
            return false;
        } else {
            return true;
        }
    }

    const managementTabs = [
        { label: t('tabsHome'), value: (auth.user.role.includes('content.tag_user') || auth.user.role.includes('content.tag_admin')) ? '/content/tags' : '/management' },
        { label: t('salesManagement'), value: '/management/sales', access: ['super_admin'] },
        { label: t('tabsTenants'), value: '/management/tenants', access: ['super_admin'] },
        { label: t('tabsUsers'), value: '/management/users' },
        { label: t('tabsGroups'), value: '/management/groups' },
        { label: t('tabsMonitoring'), value: '/management/device/monitoring', hidden: true },
        { label: t('tabsDeviceManagement'), value: '/management/device/management' },
        { label: t('tabsDeviceConfiguration'), value: '/management/device/configuration' },
        { label: t('tabsTenantConfiguration'), value: '/management/configuration' }
    ];

    const contentTabs = [
        { label: t('tabsTags'), value: '/content/tags' },
        { label: t('tabsSimpleLocations'), value: '/content/simplelocations' }
    ]

    React.useEffect(() => {
        props.fetchTenants(false);
        if (handleRouteAuthorization() && location.pathname === '/') {
            navigate('/management');
        }
    }, []);

    React.useEffect(() => {
        props.fetchTenant(selectedTenant);
    }, [selectedTenant]);

    React.useEffect(() => {
        if (props.tenant && props.tenant.primaryColor && props.tenant.secondaryColor) {
            setCreatedMuiTheme(createTheme({
                palette: {
                    primary: {
                        main: props.tenant.primaryColor[0] === '#' ? props.tenant.primaryColor : `#${props.tenant.primaryColor}`
                    },
                    secondary: {
                        main: props.tenant.secondaryColor[0] === '#' ? props.tenant.secondaryColor : `#${props.tenant.secondaryColor}`
                    }
                }
            }));
        }
    }, [props.tenant]);

    React.useEffect(() => {
        const splitLocation = location.pathname.split('/');
        let title = splitLocation.length > 2 ? splitLocation.at(-1) : splitLocation.at(1);
        title = title[0]?.toUpperCase() + title.slice(1);

        document.title = `${title} - TROIA AR Platform`;
    }, [location.pathname]);

    const handleSidebar = (open) => {
        typeof open === 'boolean' ? setSidebarOpen(open) : setSidebarOpen(true);
    }

    const handleSearch = (value) => {
        setTenantSearchText(value.target.value);
    }

    const handleSidebarClick = (tenant) => {
        setSelectedTenant(tenant.id);
        setSidebarOpen(false);
    }

    return (
        <div className={props.classes.root}>
            <ThemeProvider theme={createdMuiTheme}>
                <Sidebar 
                    data={tenantSearchText.length < 1 ? props.tenants : props.tenants.filter((tenant) => tenant.name.toLowerCase().includes(tenantSearchText.toLowerCase()))} 
                    isOpen={sidebarOpen}
                    open={handleSidebar}
                    searchValue={tenantSearchText}
                    onSearchChange={handleSearch}
                    onClick={handleSidebarClick}
                    onClear={() => setTenantSearchText('')}
                    tenantId={selectedTenant}
                />
                <div className={props.classes.contentContainer}>
                    <Navbar authorized={handleAuthorization(true)} handleSidebar={handleSidebar} branding={{
                        logo: { src: props.tenant.logoUrl, alt: `${props.tenant.name} logo` },
                        name: props.tenant.name
                    }} />
                    {(location.pathname.startsWith('/management') && location.pathname !== '/management') && (
                        <div style={{ marginTop: '5px' }}>
                            <TabRouter tabs={managementTabs} />
                        </div>
                    )}
                    {process.env.REACT_APP_CONTENT_MODULE_DISABLED !== 'true' && (location.pathname.startsWith('/content') || location.pathname === '/') && (
                        <div style={{ marginTop: '5px' }}>
                            <TabRouter tabs={contentTabs} />
                        </div>
                    )}
                    <React.Suspense fallback={<CenterSpinner />}>
                        <Routes>
                            <Route path='*' element={<NotFound />} />
                            { process.env.REACT_APP_CONTENT_MODULE_DISABLED !== 'true' ? [
                                <Route key={'simplelocations'} path={'/content/simplelocations/*'} element={<SimpleLocations {...props} tenantId={selectedTenant} />} />,
                                <Route key={'tags'} path={'/content/tags/*'} element={<Tags {...props} tenantId={selectedTenant} />} />,
                                <Route key={'tagshome'} path={'/'} element={<Tags {...props} tenantId={selectedTenant} />} />
                            ] : [
                                <Route key={'managementhome'} path={'/'} element={<Dashboard {...props} tenantId={selectedTenant} />} />
                            ]}
                            { handleRouteAuthorization() && [
                                <Route key={'configuration'} path={'/management/configuration/*'} element={<Configuration {...props} tenantId={selectedTenant} />} />,
                                <Route key={'deviceConfiguration'} path={'/management/device/configuration/*'} element={<DeviceConfiguration {...props} tenantId={selectedTenant} />} />,
                                <Route key={'monitoring'} path={'/management/device/monitoring/*'} element={<DeviceMonitoring {...props} tenantId={selectedTenant} />} />,
                                <Route key={'management'} path={'/management/device/management/*'} element={<Devices {...props} tenantId={selectedTenant} />} />,
                                <Route key={'users'} path={'/management/users/*'} element={<Users {...props} tenantId={selectedTenant} />} />,
                                <Route key={'groups'} path={'/management/groups/*'} element={<Groups {...props} tenantId={selectedTenant} />} />,
                                <Route key={'management'} path={'/management'} element={<Dashboard {...props} tenantId={selectedTenant} />} />,
                                auth.user.role.includes('super_admin') && <Route key={'tenants'} path={'/management/tenants/*'} element={<Tenants {...props} />} />,
                                auth.user.role.includes('super_admin') && <Route key={'sales'} path={'/management/sales/*'} element={<Sales {...props} />} />,
                            ]}
                        </Routes>
                    </React.Suspense>
                </div>
            </ThemeProvider>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { tenants, tenant } = state.tenant;
    return {
        tenants, tenant
    }
};
const mapDispatchToProps = {
    fetchTenants, fetchTenant
}

Main.propTypes = {
    classes: PropTypes.object,
    fetchTenants: PropTypes.func,
    fetchTenant: PropTypes.func,
    state: PropTypes.object,
    tenant: PropTypes.object,
    tenants: PropTypes.array
}

export default connect(mapStateToProps, mapDispatchToProps)(withTransaction('Main module', 'module')(withStyles(Main, styles)));
