export const FETCH_TAG_CONTENTS = 'fetch_tag_contents';
export const FETCH_TAG_CONTENTS_SUCCESS = 'fetch_tag_contents_success';
export const FETCH_TAG_CONTENTS_FAILURE = 'fetch_tag_contents_failure';
export const FETCH_TAG_CONTENTS_FINAL = 'fetch_tag_contents_final';

export const FETCH_TAG_CONTENT_ITEM = 'fetch_tag_content_item';
export const FETCH_TAG_CONTENT_ITEM_SUCCESS = 'fetch_tag_content_item_success';
export const FETCH_TAG_CONTENT_ITEM_FAILURE = 'fetch_tag_content_item_failure';
export const FETCH_TAG_CONTENT_ITEM_FINAL = 'fetch_tag_content_item_final';

export const ADD_TAG_CONTENT = 'add_tag_content';
export const ADD_TAG_CONTENT_SUCCESS = 'add_tag_content_success';
export const ADD_TAG_CONTENT_FAILURE = 'add_tag_content_failure';

export const UPDATE_TAG_CONTENT = 'update_tag_content';
export const UPDATE_TAG_CONTENT_SUCCESS = 'update_tag_content_success';
export const UPDATE_TAG_CONTENT_FAILURE = 'update_tag_content_failure';

export const DELETE_TAG_CONTENT = 'delete_tag_content';
export const DELETE_TAG_CONTENT_SUCCESS = 'delete_tag_content_success';
export const DELETE_TAG_CONTENT_FAILURE = 'delete_tag_content_failure';