import { createTheme } from '@mui/material';

export const actionButtonTheme = createTheme({
    palette: {
        primary: {
            main: '#27ae60',
            light: '#30D073',
            dark: '#229652'
        },
        secondary: {
            main: '#c0392b',
            light: '#D34D3F',
            dark: '#A43225'
        }
    }
});

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#FFEC00',
      light: '#FFEC00',
      dark: '#C5B800'
    },
    secondary: {
      main: '#A09E9F',
      light: '#A09E9F',
      dark: '#4C4C47'
    }
  }
});

export const roleThemes = {
  super_admin: createTheme({
    palette: {
      primary: {
        main: '#FF7600',
      },
      secondary: {
        main: '#FF7600',
      }
    }
  }),
  tenant_owner: createTheme({
    palette: {
      primary: {
        main: '#FFDB00',
      },
      secondary: {
        main: '#FFDB00',
      }
    }
  }),
  tenant_admin: createTheme({
    palette: {
      primary: {
        main: '#8D3B72',
      },
      secondary: {
        main: '#8D3B72',
      }
    }
  }),
  'content.tag_admin': createTheme({
    palette: {
      primary: {
        main: '#85FFC7',
      },
      secondary: {
        main: '#85FFC7',
      }
    }
  }),
  'content.tag_user': createTheme({
    palette: {
      primary: {
        main: '#297373',
      },
      secondary: {
        main: '#297373',
      }
    }
  })
}