export const FETCH_SCHEMAS = 'fetch_schemas';
export const FETCH_SCHEMAS_SUCCESS = 'fetch_schemas_success';
export const FETCH_SCHEMAS_FAILURE = 'fetch_schemas_failure';
export const FETCH_SCHEMAS_FINAL = 'fetch_schemas_final';

export const CREATE_SCHEMA = 'create_schema';
export const CREATE_SCHEMA_SUCCESS = 'create_schema_success';
export const CREATE_SCHEMA_FAILURE = 'create_schema_failure';
export const CREATE_SCHEMA_FINAL = 'create_schema_final';

export const UPDATE_SCHEMA = 'update_schema';
export const UPDATE_SCHEMA_SUCCESS = 'update_schema_success';
export const UPDATE_SCHEMA_FAILURE = 'update_schema_failure';
export const UPDATE_SCHEMA_FINAL = 'update_schema_final';

export const DELETE_SCHEMA = 'delete_schema';
export const DELETE_SCHEMA_SUCCESS = 'delete_schema_success';
export const DELETE_SCHEMA_FAILURE = 'delete_schema_failure';
export const DELETE_SCHEMA_FINAL = 'delete_schema_final';

export const FETCH_TENANT_SCHEMAS = 'fetch_tenant_schemas';
export const FETCH_TENANT_SCHEMAS_SUCCESS = 'fetch_tenant_schemas_success';
export const FETCH_TENANT_SCHEMAS_FAILURE = 'fetch_teannt_schemas_failure';
export const FETCH_TENANT_SCHEMAS_FINAL = 'fetch_tenant_schemas_final';

export const UPDATE_TENANT_SCHEMAS = 'update_tenant_schemas';
export const UPDATE_TENANT_SCHEMAS_SUCCESS = 'update_tenant_schemas_success';
export const UPDATE_TENANT_SCHEMAS_FAILURE = 'update_tenant_schemas_failure';


export const FETCH_CONFIGURATION = 'fetch_configuration';
export const FETCH_CONFIGURATION_SUCCESS = 'fetch_configuration_success';
export const FETCH_CONFIGURATION_FAILURE = 'fetch_configuration_failure';
export const FETCH_CONFIGURATION_FINAL = 'fetch_configuration_final';

export const UPDATE_TENANT_CONFIGURATION = 'update_tenant_configuration';
export const UPDATE_TENANT_CONFIGURATION_SUCCESS = 'update_tenant_configuration_success';
export const UPDATE_TENANT_CONFIGURATION_FAILURE = 'update_tenant_configuration_failure';

export const DELETE_TENANT_CONFIGURATION = 'delete_tenant_configuration';
export const DELETE_TENANT_CONFIGURATION_SUCCESS = 'delete_tenant_configuration_success';
export const DELETE_TENANT_CONFIGURATION_FAILURE = 'delete_tenant_configuration_failure';


export const FETCH_GROUP_CONFIGURATION = 'fetch_group_configuration';
export const FETCH_GROUP_CONFIGURATION_SUCCESS = 'fetch_group_configuration_success';
export const FETCH_GROUP_CONFIGURATION_FAILURE = 'fetch_group_configuration_failure';
export const FETCH_GROUP_CONFIGURATION_FINAL = 'fetch_group_configuration_final';

export const UPDATE_GROUP_CONFIGURATION = 'update_group_configuration';
export const UPDATE_GROUP_CONFIGURATION_SUCCESS = 'update_group_configuration_success';
export const UPDATE_GROUP_CONFIGURATION_FAILURE = 'update_group_configuration_failure';

export const DELETE_GROUP_CONFIGURATION = 'delete_group_configuration';
export const DELETE_GROUP_CONFIGURATION_SUCCESS = 'delete_group_configuration_success';
export const DELETE_GROUP_CONFIGURATION_FAILURE = 'delete_group_configuration_failure';


export const FETCH_DEVICE_CONFIGURATION = 'fetch_device_configuration';
export const FETCH_DEVICE_CONFIGURATION_SUCCESS = 'fetch_device_configuration_success';
export const FETCH_DEVICE_CONFIGURATION_FAILURE = 'fetch_device_configuration_failure';
export const FETCH_DEVICE_CONFIGURATION_FINAL = 'fetch_device_configuration_final';

export const UPDATE_DEVICE_CONFIGURATION = 'update_device_configuration';
export const UPDATE_DEVICE_CONFIGURATION_SUCCESS = 'update_device_configuration_success';
export const UPDATE_DEVICE_CONFIGURATION_FAILURE = 'update_device_configuration_failure';

export const DELETE_DEVICE_CONFIGURATION = 'delete_device_configuration';
export const DELETE_DEVICE_CONFIGURATION_SUCCESS = 'delete_device_configuration_success';
export const DELETE_DEVICE_CONFIGURATION_FAILURE = 'delete_device_configuration_failure';