// Lib imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { AppBar, Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Popover, Toolbar, Tooltip, Typography } from '@mui/material';
import { AccountCircle, AlternateEmail, BusinessCenter, ExitToApp, Face, Menu, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

// Project imports
import TroiaARLogo from '../assets/troia-logo-AR.png';
import useAuth from '../utils/useAuth';
import { languages } from '../i18n';
import countryNameParser from '../utils/countryNameParser';


const styles = () => ({

    root: {
        width: '100%',
        zIndex: 10,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    branding: {
        display: 'flex', 
        alignItems: 'center'
    },
    brandLogo: {
        height: '45px',
        '@media screen and (max-width: 720px)': {
            height: '30px',
        },
        cursor: 'pointer'
    },
    actions: {
        display: 'flex',
    },
    userProfile: {
        display: 'flex',
        alignItems: 'center',
    },
    signOutIcon: {
        backgroundColor: '#c0392b'
    }
});


const Navbar = (props) => {
    const [elementAnchor, setElementAnchor] = React.useState(null);
    const [userDetailsOpen, setUserDetailsOpen] = React.useState(false);
    const [languageSelectOpen, setLanguageSelectOpen] = React.useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleTenantBrandClick = () => {
        navigate((auth.user.role.includes('content.tag_admin') || auth.user.role.includes('content.tag_user')) ? '/' : '/management');
    }

    const handleSignoutClick = () => {
        auth.logout();
    }

    const handlePopoverClose = () => {
        setUserDetailsOpen(false);
        setLanguageSelectOpen(false);
        setElementAnchor(null);
    }

    const getRoleInfo = () => {
        if (auth.user.role.includes('super_admin')) {
            return 'Super Admin'
        } else if (auth.user.role.includes('tenant_owner')) {
            return 'Tenant Owner'
        } else if (auth.user.role.includes('tenant_admin')) {
            return 'Tenant Admin'
        } else {
            return 'No roles'
        }
    }

    return (
        <AppBar className={props.classes.root} color="inherit" position="static">
            <Toolbar className={props.classes.toolbar}>
                <div className={props.classes.branding}>
                    {props.authorized && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleSidebar}
                            data-cy="navbarToggleSidebar"
                            size="large">
                            <Menu />
                        </IconButton>
                    )}
                    <span style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', alignItems: 'center' }}>
                        <img onClick={handleTenantBrandClick} src={props.branding && props.branding.logo ? props.branding.logo.src : TroiaARLogo} alt={props.branding && props.branding.logo ? props.branding.logo.alt : 'Troia AR Logo'} className={props.classes.brandLogo} />
                        {props.branding && props.branding.name && auth.user.role.includes('super_admin') && (
                            <Typography variant="caption">{props.branding.name}</Typography>
                        )}
                    </span>
                </div>
                <div className={props.classes.actions}>
                    <span className={props.classes.userProfile}>
                        <Tooltip title={t('languageselect')}>
                            <IconButton onClick={(e) => {
                                setElementAnchor(e.currentTarget);
                                setLanguageSelectOpen(true);
                            }} size="large" data-cy="language-button">
                                <ReactCountryFlag
                                    countryCode={i18n.language.split('-')[1] || 'GB'}
                                    svg
                                    style={{
                                        width: '0.9em',
                                        height: '0.9em'
                                    }}
                                    title={countryNameParser(i18n.language)}
                                />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={languageSelectOpen}
                            onClose={handlePopoverClose}
                            anchorEl={elementAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            <List>
                                {languages.map((language) => (
                                    <ListItem key={language}>
                                        <ListItemButton data-cy="language" onClick={() => {
                                            i18n.changeLanguage(language);
                                            handlePopoverClose();
                                        }}>
                                            <ListItemIcon>
                                                <ReactCountryFlag
                                                    countryCode={language.split('-')[1]}
                                                    svg
                                                    style={{
                                                        width: '2em',
                                                        height: '2em'
                                                    }}
                                                    title={countryNameParser(language)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={countryNameParser(language)} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Popover>
                        <Tooltip title={t('accountinfo')}>
                            <IconButton onClick={(e) => {
                                setElementAnchor(e.currentTarget);
                                setUserDetailsOpen(true);
                            }} size="large">
                                <AccountCircle />
                            </IconButton>
                        </Tooltip>
                        <Popover 
                            open={userDetailsOpen} 
                            onClose={handlePopoverClose}
                            anchorEl={elementAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Face />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${auth.user.name || ''}`} secondary={t('fullname')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Person />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={auth.user.preferred_username} secondary={t('username')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AlternateEmail />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={auth.user.email} secondary={t('email')} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BusinessCenter />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={getRoleInfo()} secondary={t('role')} />
                                </ListItem>
                                <Divider sx={{ margin: '7px 16px' }} />
                                <ListItem onClick={handleSignoutClick} aria-label={t('signout')} sx={{ cursor: "pointer", backgroundColor: 'rgba(255, 255, 255, 1)', transition: 'background-color ease-in-out 0.15s', ":hover": { backgroundColor: 'rgba(0, 0, 0, 0.05)' }}}>
                                    <ListItemAvatar>
                                        <Avatar classes={{ colorDefault: props.classes.signOutIcon }}>
                                            <ExitToApp />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={t('signout')} secondary={t('account')} />
                                </ListItem>
                            </List>
                        </Popover>
                    </span>
                </div>
            </Toolbar>
        </AppBar>
    );
}

Navbar.propTypes = {
    classes: PropTypes.object,
    handleSidebar: PropTypes.func.isRequired,
    branding: PropTypes.shape({
        logo: PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            alt: PropTypes.string,
        }.isRequired),
        name: PropTypes.string,
    }.isRequired),
    authorized: PropTypes.bool,
}

export default withStyles(Navbar, styles);
