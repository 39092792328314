// Library imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import { connect } from 'react-redux';
import { green, amber } from '@mui/material/colors';
import { CheckCircle, Warning, Error, Info, Close } from '@mui/icons-material';

// Project imports
import { notifyClose } from '../actions';

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
};

const styles = theme => ({
    success: {
        backgroundColor: `${green[600]} !important`,
        [`$ .MuiPaper-root`]: {
            backgroundColor: `${green[600]} !important`,
        }
    },
    error: {
        backgroundColor: `${theme.palette.error.dark} !important`,
        [`$ .MuiPaper-root`]: {
            backgroundColor: `${green[600]} !important`,
        }
    },
    info: {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        [`$ .MuiPaper-root`]: {
            backgroundColor: `${theme.palette.primary.dark} !important`,
        }
    },
    warning: {
        backgroundColor: `${amber[700]} !important`,
        [`$ .MuiPaper-root`]: {
            backgroundColor: `${amber[700]} !important`,
        }
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    closeIcon: {
        fontSize: 20
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        padding: theme.spacing(0.5),
    }
});

const Notifications = (props) => {

    const Icon = variantIcon[props.type];

    const handleClose = () => {
        props.notifyClose();
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <SnackbarContent
                classes={{ root: props.classes[props.type] }}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={props.classes.message}>
                        <Icon className={props.classes.icon} />
                        {props.text}
                    </span>
                }
                action={
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={props.classes.close}
                        onClick={handleClose}
                        size="large">
                        <Close className={props.classes.closeIcon} />
                    </IconButton>
                }
            />
        </Snackbar>
    )
}

const mapStateToProps = (state) => {
    const { open, type, text } = state.notification;
    return {
        open, type, text
    }
};
const mapDispatchToProps = {
    notifyClose
}

Notifications.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    text: PropTypes.string.isRequired,
    notifyClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Notifications, styles));
