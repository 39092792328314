export const FETCH_TENANTS = 'fetch_tenants';
export const FETCH_TENANTS_SUCCESS = 'fetch_tenants_success';
export const FETCH_TENANTS_FAILURE = 'fetch_tenants_failure';
export const FETCH_TENANTS_FINAL = 'fetch_tenants_final';

export const FETCH_TENANT = 'fetch_tenant';
export const FETCH_TENANT_SUCCESS = 'fetch_tenant_success';
export const FETCH_TENANT_FAILURE = 'fetch_tenant_failure';
export const FETCH_TENANT_FINAL = 'fetch_tenant_final';

export const FETCH_TENANTS_WITH_SALES_DATA = 'fetch_tenants_with_sales_data';
export const FETCH_TENANTS_WITH_SALES_DATA_SUCCESS = 'fetch_tenants_with_sales_data_success';
export const FETCH_TENANTS_WITH_SALES_DATA_FAILURE = 'fetch_tenants_with_sales_data_failure';
export const FETCH_TENANTS_WITH_SALES_DATA_FINAL = 'fetch_tenants_with_sales_data_final';

export const CREATE_TENANT = 'create_tenant';
export const CREATE_TENANT_SUCCESS = 'create_tenant_success';
export const CREATE_TENANT_FAILURE = 'create_tenant_failure';

export const UPDATE_TENANT = 'update_tenant';
export const UPDATE_TENANT_SUCCESS = 'update_tenant_success';
export const UPDATE_TENANT_FAILURE = 'update_tenant_failure';

export const DELETE_TENANT = 'delete_tenant';
export const DELETE_TENANT_SUCCESS = 'delete_tenant_success';
export const DELETE_TENANT_FAILURE = 'delete_tenant_failure';