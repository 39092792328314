import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import axios from '../utils/customAxios';

import { FETCH_TAG_LOCATIONS, FETCH_TAG_LOCATION, CREATE_TAG_LOCATION, UPDATE_TAG_LOCATION, DELETE_TAG_LOCATION } from '../constants/tagLocationsTypes';
import { fetchTagLocations as fetchTagLocationsAction, notifyOpen, fetchTagLocationSuccess, fetchTagLocationFailure, fetchTagLocationFinal, fetchTagLocationsSuccess, fetchTagLocationsFailure, fetchTagLocationsFinal } from '../actions';

function fetchTagLocationsRequest(tenantId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Locations`);
}

function* fetchTagLocations({ payload }) {
    try {
        const { data, headers } = yield call(fetchTagLocationsRequest, payload.tenantId);

        yield put(fetchTagLocationsSuccess({
            data, headers
        }));
    } catch (error) {
        yield put(fetchTagLocationsFailure());
    } finally {
        yield put(fetchTagLocationsFinal());
    }
}

export function* watchFetchTagLocations() {
    yield takeLatest(FETCH_TAG_LOCATIONS, fetchTagLocations);
}

function fetchTagLocationRequest(tagLocationId) {
    return axios.get(`${process.env.REACT_APP_COMMON_API_BASE}/Locations/${tagLocationId}`);
}

function* fetchTagLocation({ payload }) {
    try {
        const { data } = yield call(fetchTagLocationRequest, payload.tagLocationId);

        yield put(fetchTagLocationSuccess(data));
    } catch (error) {
        yield put(fetchTagLocationFailure());
    } finally {
        yield put(fetchTagLocationFinal());
    }
}

export function* watchFetchTagLocation() {
    yield takeLatest(FETCH_TAG_LOCATION, fetchTagLocation);
}

function createTagLocationRequest(tenantId, tagLocation) {
    return axios.post(`${process.env.REACT_APP_COMMON_API_BASE}/Tenants/${tenantId}/Locations`, tagLocation);
}

function* createTagLocation({ payload }) {
    try {
        yield call(createTagLocationRequest, payload.tenantId, payload.tagLocation);
        yield put(notifyOpen({
            text: 'Successfully added location',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to add location',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagLocationsAction(payload.tenantId));
    }
}

export function* watchCreateTagLocation() {
    yield takeLatest(CREATE_TAG_LOCATION, createTagLocation);
}

function updateTagLocationRequest(tagLocation) {
    return axios.put(`${process.env.REACT_APP_COMMON_API_BASE}/Locations/${tagLocation.id}`, tagLocation);
}

function* updateTagLocation({ payload }) {
    try {
        yield call(updateTagLocationRequest, payload.tagLocation);
        yield put(notifyOpen({
            text: 'Successfully updated location',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to update location',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagLocationsAction(payload.tenantId));
    }
}

export function* watchUpdateTagLocation() {
    yield takeLatest(UPDATE_TAG_LOCATION, updateTagLocation);
}

function deleteTagLocationRequest(tagLocationId) {
    return axios.delete(`${process.env.REACT_APP_COMMON_API_BASE}/Locations/${tagLocationId}`);
}

function* deleteTagLocation({ payload }) {
    try {
        yield call(deleteTagLocationRequest, payload.tagLocationId);
        yield put(notifyOpen({
            text: 'Successfully deleted location',
            type: 'success'
        }));
    } catch (error) {
        yield put(notifyOpen({
            text: 'Failed to delete location',
            type: 'error'
        }));
    } finally {
        yield put(fetchTagLocationsAction(payload.tenantId));
    }
}

export function* watchDeleteTagLocation() {
    yield takeLatest(DELETE_TAG_LOCATION, deleteTagLocation);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchTagLocations),
        fork(watchFetchTagLocation),
        fork(watchCreateTagLocation),
        fork(watchUpdateTagLocation),
        fork(watchDeleteTagLocation)
    ]);
}
